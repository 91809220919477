import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IDeleteButtonProps extends Omit<ICustomButtonProps, "onClick"> {
  onDelete?: () => void;
  showConfirm?: boolean;
  confirmDeleteMessage?: string;
}

export const DeleteButton: React.FunctionComponent<IDeleteButtonProps> = ({
  minimal = true,
  onDelete,
  loading,
  disabled,
  showConfirm = true,
  intent = Intent.DANGER,
  confirmDeleteMessage,
  text,
  title,
  small,
}) => {
  const { t, showDialog } = useCommonHooks();
  const onInnerDelete = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      let ok = true;
      if (showConfirm) {
        const res = await showDialog({
          message: confirmDeleteMessage || t(ETLCodes.ConfirmDelete),
          title: t(ETLCodes.Delete),
          buttons: [
            {
              text: t(ETLCodes.No),
              intent: "none",
              resultType: "no",
            },
            {
              text: t(ETLCodes.Yes),
              intent: "danger",
              resultType: "yes",
            },
          ],
        });
        ok = res === "yes";
      }
      if (ok) {
        onDelete();
      }
    },
    [confirmDeleteMessage, onDelete, showConfirm, showDialog, t]
  );
  return (
    <Button
      tabIndex={-1}
      title={title}
      icon={IconNames.TRASH}
      minimal={minimal}
      onClick={onInnerDelete}
      text={text || (minimal ? null : t(ETLCodes.Delete))}
      intent={intent}
      loading={loading}
      disabled={disabled}
      type="button"
      small={small}
    />
  );
};
