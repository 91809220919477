import React from "react";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { FullPageLoader } from "./components";
import {
  AuthProvider,
  DialogProvider,
  EventsProvider,
  GlobalDataProvider,
  NavigationProvider,
} from "./contexts";
import { HotkeysProvider } from "@blueprintjs/core";
import { App } from "./App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // query options
      refetchOnWindowFocus: false,
    },
    mutations: {
      // mutation options
    },
  },
});

interface AppProviderProps {
  children: React.ReactNode;
}
const Root = () => (
  <EventsProvider>
    <AuthProvider>
      <HotkeysProvider>
        <GlobalDataProvider>
          <NavigationProvider>
            <App />
          </NavigationProvider>
        </GlobalDataProvider>
      </HotkeysProvider>
    </AuthProvider>
  </EventsProvider>
);

export const AppProvider: React.FC<AppProviderProps> = () => {
  const router = createHashRouter([
    {
      path: "*",
      Component: Root,
    },
  ]);
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <DialogProvider>
            <RouterProvider router={router} />
          </DialogProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </React.Suspense>
  );
};
