import { Icon, IconName, Intent } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import React from "react";
import styled from "styled-components";

const StyledTooltip = styled(Tooltip2)`
  padding-top: 0.3rem;
`;

export interface IInformationTooltipProps {
  text: string;
  icon: IconName;
  intent?: Intent;
}

export const InformationTooltip: React.FunctionComponent<
  IInformationTooltipProps
> = ({ text, icon, intent = Intent.NONE }) => {
  return (
    <StyledTooltip content={text}>
      <Icon icon={icon} intent={intent} />
    </StyledTooltip>
  );
};
