import React, { useMemo } from "react";
import styled from "styled-components";
import { useDisplayLanguages } from "../hooks";
import { Popover2 } from "@blueprintjs/popover2";
import { Button, Classes, Menu, MenuItem } from "@blueprintjs/core";
import { getTheme, SiteTheme } from "../theme";

const StyledButton = styled(Button)<{ theme: SiteTheme }>`
  color: ${({ theme }) => getTheme(theme).menuTextColor} !important;

  & > .${Classes.ICON} {
    color: ${({ theme }) => getTheme(theme).menuTextColor} !important;
  }
`;

const Container = styled.div``;

export interface LanguageSelectorProps {
  hideCurrentLanguage?: boolean;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  hideCurrentLanguage = false,
}) => {
  const { languages, changeLanguage, currentLanguage } = useDisplayLanguages();
  const listedLanguages = useMemo(
    () =>
      currentLanguage && hideCurrentLanguage
        ? languages.filter((l) => l.code !== currentLanguage.code)
        : languages,
    [currentLanguage, hideCurrentLanguage, languages]
  );

  if (!currentLanguage) return null;

  return (
    <Container>
      <Popover2
        content={
          <Menu>
            {listedLanguages.map((lg) => (
              <MenuItem
                key={lg.code}
                text={lg.label}
                onClick={() => changeLanguage(lg.code)}
              />
            ))}
          </Menu>
        }
        position={"bottom-right"}
        minimal
      >
        <StyledButton
          rightIcon={"caret-down"}
          icon={"flag"}
          text={currentLanguage.label}
          large
          minimal
        />
      </Popover2>
    </Container>
  );
};
