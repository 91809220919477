import React, { useState } from "react";

import { AccountApi, ActionCode, UserProfileDto } from "../api";
import { baseUrl } from "../apiConfig";
import { useFrontendSettings } from "../hooks/applicationHooks/useFrontendSettings";
import Keycloak, { KeycloakInitOptions, KeycloakProfile } from "keycloak-js";
import { useRouter } from "../hooks";

interface IAuthProviderProps {
  children: React.ReactNode;
}

export interface ErrorDetails {
  statusCode?: number;
  message?: string | null;
}

const AuthContext = React.createContext<IAuthContext>(null);

export const AuthProvider: React.FunctionComponent<IAuthProviderProps> = ({
  children,
}) => {
  const [user, setUser] = React.useState<UserProfileDto>();
  const [keycloakUser, setKeycloakUser] = React.useState<KeycloakProfile>();
  const isLoggedIn = React.useMemo(() => !!user, [user]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loginError, setLoginError] = React.useState();
  const { frontendSettings } = useFrontendSettings();
  const [keycloak, setKeycloak] = useState<Keycloak>();
  const router = useRouter();

  React.useEffect(() => {
    if (!frontendSettings) return;
    const keycloak = new Keycloak({
      url: frontendSettings.keycloakUrl,
      realm: frontendSettings.keycloakRealm!,
      clientId: frontendSettings.keycloakClientId!,
    });

    const initOptions: KeycloakInitOptions = {
      onLoad: "login-required",
      checkLoginIframe: false,
    };

    let refreshInterval: NodeJS.Timeout;
    console.log("Initializing Keycloak ...");
    keycloak.init(initOptions).then((authenticated) => {
      if (authenticated) {
        keycloak.loadUserProfile().then(setKeycloakUser);

        refreshInterval = setInterval(() => {
          keycloak
            .updateToken(70)
            .then(() => {})
            .catch(() => {});
        }, 60000); // Refresh token every minute

        //xha: hack used to intercept unwanted rerouting to default page.
        const cleanedHash = window.location.hash
          .replace(/[&?]code=[^&$]*/, "")
          .replace(/[&?]scope=[^&$]*/, "")
          .replace(/[&?]state=[^&$]*/, "")
          .replace(/[&?]iss=[^&$]*/, "")
          .replace(/[&?]session_state=[^&$]*/, "");
        router.push(cleanedHash.substring(2));
      } else {
        console.error("user is not authenticaded, something must be wrong ...");
      }
      setKeycloak(keycloak);
    });
    return () => refreshInterval && clearInterval(refreshInterval);
  }, [frontendSettings]);

  const logout = React.useCallback(async () => {
    if (!keycloak) return;
    await keycloak.logout();
    setKeycloakUser(undefined);
  }, [keycloak]);

  const getAccessToken = React.useCallback(async () => {
    const s = keycloak ? "Bearer " + keycloak.token : "";
    return s;
  }, [keycloak]);

  React.useEffect(() => {
    if (keycloakUser && keycloak.token) {
      console.log("Keycloak user ready, getting user profile...");

      const api = new AccountApi({
        basePath: baseUrl,
        middlewares: [],
        apiKeyPromise: getAccessToken,
      });

      api
        .profile()
        .then((user) => {
          console.log("User profile loaded", user);
          setUser(user);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("User profile error", error);
          setLoginError(error);
          setIsLoading(false);
        });
    }
  }, [getAccessToken, keycloak, keycloak?.token, keycloakUser]);

  const hasAction = React.useCallback(
    (actionList: ActionCode[] = []) => {
      if (actionList.length === 0) return true;
      return actionList.filter((v) => user?.actions?.includes(v)).length > 0;
    },
    [user?.actions]
  );

  const fullUserName = React.useMemo(
    () => keycloakUser?.firstName + " " + keycloakUser?.lastName,
    [keycloakUser]
  );

  return (
    <AuthContext.Provider
      value={{
        error: loginError,
        hasAction,
        isLoading,
        logout,
        user,
        getAccessToken,
        isLoggedIn,
        fullUserName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface IAuthContext {
  logout: () => void;
  isLoading: boolean;
  user?: UserProfileDto;
  error?: ErrorDetails;
  hasAction: (actionList: ActionCode[]) => boolean;
  getAccessToken: () => Promise<string>;
  isLoggedIn: boolean;
  fullUserName: string;
}

export const useAuth = () => React.useContext(AuthContext);
