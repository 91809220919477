import "@fortawesome/fontawesome-free/css/all.min.css";
import "normalize.css/normalize.css";
import { ReactQueryDevtools } from "react-query/devtools";

import {
  Classes,
  Colors,
  Drawer,
  NonIdealState,
  Position,
} from "@blueprintjs/core";
import { useLocalStorage } from "nsitools-react";
import React from "react";
import styled from "styled-components";

import { BaseAppNavigation } from "./app-navigation";
import { AppRouter } from "./AppRouter";
import {
  DialogRoot,
  Footer,
  FullPageLoader,
  Header,
  NAVIGATION_MENU_WIDTH,
  NavigationMenu,
} from "./components";
import { AppError } from "./components/AppError";
import { useAuth, useGlobalData } from "./contexts";
import { useCommonHooks, useTl } from "./hooks";
import { ETLCodes } from "./locales";
import { IThemeData } from "./theme";

const AppContainer = styled.div<{ isdark?: boolean; theme: IThemeData }>`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  background-color: ${Colors.LIGHT_GRAY5};

  & .${Classes.MENU} {
    background: ${(props) => props.theme.menuColor} !important;
  }

  & .${Classes.TAB_PANEL} {
    width: 100%;
  }

  & .app-drawer {
    & .${Classes.DRAWER_BODY} {
      background: ${(props) => props.theme.menuColor} !important;
    }

    & .${Classes.DRAWER_FOOTER} {
      background: ${(props) => props.theme.menuColor} !important;
    }
  }

  & .${Classes.TAB} {
    background-color: ${(props) => props.theme.tabBackground} !important;
    color: ${(props) => props.theme.tabText} !important;
    &[aria-selected="true"] {
      background-color: ${(props) =>
        props.theme.tabBackgroundSelected} !important;
      color: ${(props) => props.theme.tabTextSelected} !important;
    }
    &:hover {
      color: ${(props) => props.theme.tabTextHover} !important;
    }
    font-weight: ${(props) =>
      props.theme.tabBold ? "bold" : "normal"} !important;
  }

  & .${Classes.TAB_INDICATOR} {
    background-color: ${(props) =>
      props.theme.tabShowIndicator
        ? props.theme.tabTextHover
        : "transparent"} !important;
  }
`;

const ContentContainer = styled.div<{ theme: IThemeData }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-wrap: wrap;
`;

const FixedNavigationContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
`;

const FooterContainer = styled.div``;

export interface IAppProps {}

export const App: React.FunctionComponent<IAppProps> = () => {
  const [menuFixed, setMenuFixed] = useLocalStorage("menuFixed", true);
  const { isLoading: authLoading, error: authError, isLoggedIn } = useAuth();

  const [drawerVisible, setDrawerVisible] = React.useState(false);

  const { loading, appVersion, backendNotReachable } = useGlobalData();
  const { theme } = useCommonHooks();

  const appIsLoading = React.useMemo(
    () => loading || authLoading,
    [loading, authLoading]
  );

  const { t } = useTl();

  const content = React.useMemo(() => {
    if (backendNotReachable) {
      return (
        <NonIdealState icon="th-disconnect" title={"Backend not reachable"} />
      );
    }

    if (authError || !isLoggedIn) {
      return (
        <NonIdealState
          icon="error"
          title={t(ETLCodes.NotAuthorized)}
          description={t(ETLCodes.UserNotAuthorizedMessage)}
        />
      );
    }

    return (
      <>
        <Drawer
          hasBackdrop={true}
          className={`${Classes.DARK} app-drawer`}
          isOpen={drawerVisible}
          position={Position.LEFT}
          size={NAVIGATION_MENU_WIDTH}
          onClose={() => setDrawerVisible(false)}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          usePortal={false}
        >
          <div className={Classes.DRAWER_BODY}>
            <NavigationMenu
              enableQuickAccess={false}
              collapseOtherOnSelection={true}
              maxAutoCollapseLevel={0}
              navigationMenu={BaseAppNavigation}
              isFixed={menuFixed}
              changeFixed={() => {
                if (!menuFixed) {
                  setDrawerVisible(false);
                }
                setMenuFixed(!menuFixed);
              }}
              onNavigation={() => setDrawerVisible(false)}
            />
          </div>
          <div className={Classes.DRAWER_FOOTER} />
        </Drawer>
        {menuFixed && (
          <FixedNavigationContainer>
            <NavigationMenu
              enableQuickAccess={false}
              navigationMenu={BaseAppNavigation}
              collapseOtherOnSelection={true}
              maxAutoCollapseLevel={0}
              isFixed={menuFixed}
              changeFixed={() => setMenuFixed(!menuFixed)}
            />
          </FixedNavigationContainer>
        )}
        <AppRouter />
      </>
    );
  }, [
    authError,
    backendNotReachable,
    drawerVisible,
    isLoggedIn,
    menuFixed,
    setMenuFixed,
    t,
  ]);

  return (
    <AppContainer theme={theme}>
      {appIsLoading ? (
        <FullPageLoader />
      ) : (
        <AppError>
          <Header
            showMenuIcon={!menuFixed}
            toggleLeftMenu={() => {
              setDrawerVisible((s) => !s);
            }}
          />

          <ContentContainer className="page">{content}</ContentContainer>
          <FooterContainer>
            <Footer versionLoading={loading} version={appVersion} />
          </FooterContainer>
          <DialogRoot />
        </AppError>
      )}
      <ReactQueryDevtools />
    </AppContainer>
  );
};
