import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IExportButtonProps extends ICustomButtonProps {}

export const ExportButton: React.FunctionComponent<IExportButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY,
  text,
  hideText,
  hidden = false,
}) => {
  const { t } = useTl();

  return (
    <Button
      icon={IconNames.EXPORT}
      minimal={minimal}
      onClick={onClick}
      text={!hideText ? text || t(ETLCodes.Export) : null}
      intent={intent}
      loading={loading}
      disabled={disabled}
      hidden={hidden}
    />
  );
};
