import { Colors } from "@blueprintjs/core";
import { lighten } from "polished";

import assets from "./assets";

let cache_css_variables = undefined;

function getCssRootVariables() {
  if (cache_css_variables) return cache_css_variables;
  const style = getComputedStyle(document.documentElement);

  const ccbMenu = style.getPropertyValue("--ccb-menu").trim();
  const ccbMenuText = style.getPropertyValue("--ccb-menu-text").trim();
  const ccbMenuHover = style.getPropertyValue("--ccb-menu-hover").trim();

  const ccbHeader = style.getPropertyValue("--ccb-header").trim();
  const ccbHeaderText = style.getPropertyValue("--ccb-header-text").trim();

  const ccbBreadcrumb = style.getPropertyValue("--ccb-breadcrumb").trim();
  const ccbBreadcrumbText = style
    .getPropertyValue("--ccb-breadcrumb-text")
    .trim();
  const ccbBreadcrumbHover = style
    .getPropertyValue("--ccb-breadcrumb-hover")
    .trim();

  const ccbPrimary1 = style.getPropertyValue("--ccb-primary1").trim();
  const ccbPrimary2 = style.getPropertyValue("--ccb-primary2").trim();
  const ccbPrimary3 = style.getPropertyValue("--ccb-primary3").trim();

  const ccbSecondary1 = style.getPropertyValue("--ccb-secondary1").trim();
  const ccbSecondary2 = style.getPropertyValue("--ccb-secondary2").trim();
  const ccbSecondary3 = style.getPropertyValue("--ccb-secondary3").trim();

  const ccbText1 = style.getPropertyValue("--ccb-text1").trim();
  const ccbText2 = style.getPropertyValue("--ccb-text2").trim();
  const ccbBackground = style.getPropertyValue("--ccb-background").trim();
  cache_css_variables = {
    ccbMenu,
    ccbMenuText,
    ccbMenuHover,
    ccbHeader,
    ccbHeaderText,
    ccbBreadcrumb,
    ccbBreadcrumbText,
    ccbBreadcrumbHover,
    ccbPrimary1,
    ccbPrimary2,
    ccbPrimary3,
    ccbSecondary1,
    ccbSecondary2,
    ccbSecondary3,
    ccbText1,
    ccbText2,
    ccbBackground,
  };
  return cache_css_variables;
}

export interface IThemeData {
  siteTheme: SiteTheme;
  headerColor: string;
  headerTextColor: string;
  menuColor: string;
  menuTextColor: string;
  menuIconColor: string;
  titleColor: string;
  selectionMenuColor: string;
  userColor: string;
  headerName: string;
  highlightColor: string;
  logoUrl: string;
  bannerUrl: string;
  primaryColor: string;
  tableStrip: string;
  tableRowHover: string;
  searchFilterHighlightColor: string;
  searchFilterHighlightColorRGB: string;
  searchFilterHighlightBackgroundColor: string;
  showEnvironment?: boolean;
  environmentColor?: string;

  breadCrumbsColor: string;
  breadCrumbsTextColor: string;
  breadcrumbHoverColor: string;

  tabText: string;
  tabTextSelected: string;
  tabTextHover: string;
  tabShowIndicator: boolean;
}

export type EnvironmentThemeData = {
  displayName: string;
  showEnvironment: boolean;
  environmentColor?: string;
  themeOverride?: Partial<IThemeData>;
};

const baseTheme: IThemeData = (() => {
  const cssRootVariables = getCssRootVariables();
  return {
    siteTheme: "base",
    primaryColor: cssRootVariables.ccbPrimary1, // rose de base
    headerColor: cssRootVariables.ccbHeader, // blanc
    headerTextColor: cssRootVariables.ccbHeaderText,
    menuColor: cssRootVariables.ccbMenu, // rose de base
    menuTextColor: cssRootVariables.ccbMenuText,
    menuIconColor: cssRootVariables.ccbMenuHover, // blanc
    selectionMenuColor: cssRootVariables.ccbPrimary3, // rose foncé
    titleColor: Colors.DARK_GRAY1,
    userColor: "transparent",
    highlightColor: "white", // rose clair
    headerName: "CCB Portal Granulat",
    logoUrl: assets.images.ccbLogo,
    bannerUrl: undefined,
    tableStrip: lighten(0.8, cssRootVariables.ccbPrimary3), //
    tableRowHover: lighten(0.5, cssRootVariables.ccbPrimary3), //
    searchFilterHighlightColor: cssRootVariables.ccbMenuHover,
    searchFilterHighlightColorRGB: "19 124 189",
    searchFilterHighlightBackgroundColor: lighten(0.6, "#00afff"),
    breadCrumbsColor: cssRootVariables.ccbBreadcrumb,
    breadCrumbsTextColor: cssRootVariables.ccbBreadcrumbText,
    breadcrumbHoverColor: cssRootVariables.ccbBreadcrumbHover,
    tabText: cssRootVariables.ccbHeaderText,
    tabTextSelected: cssRootVariables.ccbPrimary1,
    tabTextHover: cssRootVariables.ccbPrimary2,
    tabShowIndicator: true,
  };
})();

export type SiteTheme = "base";
export type SiteEnvironment = "development" | "qa" | "uat" | "prd";

const siteEnvironmentThemes: Record<SiteEnvironment, EnvironmentThemeData> = {
  development: {
    displayName: "dev",
    showEnvironment: true,
    environmentColor: "#059669",
  },
  qa: {
    displayName: "qa",
    showEnvironment: true,
    environmentColor: "#4f46e5",
  },
  uat: {
    displayName: "uat",
    showEnvironment: true,
    environmentColor: "#d97706",
  },
  prd: {
    displayName: "prd",
    showEnvironment: false,
  },
};

export function getTheme(type: SiteTheme, env?: SiteEnvironment): IThemeData {
  let theme: IThemeData;
  switch (type) {
    case "base":
      theme = baseTheme;
      break;
    default:
      theme = baseTheme;
  }
  if (env) {
    const themeOverride = getEnvironmentTheme(env).themeOverride;
    if (themeOverride) theme = { ...theme, ...themeOverride };
  }
  return theme;
}

export function getEnvironmentTheme(
  env: SiteEnvironment
): EnvironmentThemeData {
  return (
    siteEnvironmentThemes[env] || {
      displayName: env,
      showEnvironment: true,
      environmentColor: Colors.GRAY3,
      themeOverride: {},
    }
  );
}
