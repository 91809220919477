import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

export const DownloadButton: React.FunctionComponent<ICustomButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.NONE,
  text,
  icon,
}) => {
  const { t } = useTl();

  return (
    <Button
      icon={icon || IconNames.IMPORT}
      title={text}
      minimal={minimal}
      onClick={onClick}
      text={text || (minimal ? null : t(ETLCodes.Download))}
      intent={intent}
      loading={loading}
      disabled={disabled}
    />
  );
};
