import { Button, Classes, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";
import styled from "styled-components";

import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";
import { getTheme, SiteTheme } from "../theme";

export interface UserMenuProps {}

const Container = styled.div`
  background-color: ${(props) => props.color || "none"};
`;

const StyledMenu = styled(Menu)`
  /* background-color: white !important;
  color: black !important; */
`;

const StyledButton = styled(Button)<{ theme: SiteTheme }>`
  color: ${({ theme }) => getTheme(theme).menuTextColor} !important;

  & > .${Classes.ICON} {
    color: ${({ theme }) => getTheme(theme).menuTextColor} !important;
  }
`;

export const UserMenu: React.FunctionComponent<UserMenuProps> = () => {
  const { t, fullUserName, logout, theme } = useCommonHooks();
  return (
    <Container color={theme.userColor}>
      <Popover2
        content={
          <StyledMenu>
            <MenuItem
              icon="log-out"
              text={t(ETLCodes.Logout)}
              onClick={() => logout()}
            />
          </StyledMenu>
        }
        position="bottom-right"
        minimal
      >
        <StyledButton
          rightIcon="caret-down"
          icon="person"
          text={fullUserName}
          minimal
          large
        />
      </Popover2>
    </Container>
  );
};
