import * as React from "react";
import {} from "@blueprintjs/core";
import { useCommonHooks } from "../../../hooks";
import { CustomerEditDto } from "../../../api";
import { useCrudApi } from "../../../hooks/useCrudApi";
import { FGTextInput, FlexFieldGroup, showSuccess } from "nsitools-react";
import { ETLCodes } from "../../../locales";
import { ERoutes } from "../../../AppRouter";
import * as Yup from "yup";
import {
  CardFieldGroup,
  CustomFormGenerator,
  LanguageSelect,
} from "../../../components";
import CustomerTypeSelect from "../../../components/selects/CustomerTypeSelect";

export interface CustomerDetailPanelProps {
  customerId: number;
}

export const CustomerDetailPanel: React.FunctionComponent<
  CustomerDetailPanelProps
> = ({ customerId }) => {
  const { apis, t, router } = useCommonHooks();

  const isNew = React.useMemo(() => customerId <= 0, [customerId]);

  const { data, isFetching, validationErrors, onSave, isSaving } = useCrudApi({
    queryKey: ["customer-details", customerId],
    fetchFn: async () => {
      if (customerId > 0) {
        return apis.customers.get(customerId);
      }
      const newCustomer = CustomerEditDto.fromJS({});
      newCustomer.isActive = true;
      return newCustomer;
    },
    saveFn: async (toSave: CustomerEditDto) => {
      return apis.customers.save(toSave);
    },
    onSaved: (saved: CustomerEditDto) => {
      showSuccess(t(ETLCodes.CustomerSaved));
      if (isNew) {
        const routeToPush = router.getEditRoute(
          ERoutes.customers,
          saved.id,
          "details"
        );
        router.push(routeToPush);
      }
    },
    serverValidationRootKey: "Dto",
  });

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t(ETLCodes.Required)),
        languageId: Yup.number().required(t(ETLCodes.Required)),
      }),
    [t]
  );

  return (
    <FlexFieldGroup columns={2}>
      <CardFieldGroup columns={1} title={t(ETLCodes.Details)}>
        <CustomFormGenerator
          initialValues={data}
          loading={isFetching}
          onSubmit={onSave}
          saving={isSaving}
          editable={false}
          editMode={false}
          validationErrors={validationErrors}
          validationSchema={validationSchema}
        >
          <FlexFieldGroup>
            <FGTextInput name="code" label={t(ETLCodes.CustomerCode)} />
            <FGTextInput name="name" label={t(ETLCodes.Name)} />
            <CustomerTypeSelect
              name="customerType"
              label={t(ETLCodes.CustomerType)}
            />
            <LanguageSelect name="languageId" label={t(ETLCodes.Language)} />
            {/* <FGCheckboxInput
              name="isActive"
              label={t(ETLCodes.IsActive)}
              switchMode
            /> */}
          </FlexFieldGroup>
        </CustomFormGenerator>
      </CardFieldGroup>
    </FlexFieldGroup>
  );
};

export default CustomerDetailPanel;
