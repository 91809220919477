import { Classes, NonIdealState } from "@blueprintjs/core";
import { DataTable2, IDataTable2Props } from "nsitools-react";
import React from "react";
import styled from "styled-components";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { IThemeData } from "../../theme";
import { StyledNonIdealState } from "../customIcons";

export interface ICustomDataTableProps<T> extends IDataTable2Props<T> {
  searched?: boolean;
  renderNoDataEmpty?: boolean;
}

const StyledDataTable2 = styled(DataTable2)<{ theme: IThemeData }>`
  &
    table.${Classes.HTML_TABLE}.${Classes.HTML_TABLE_STRIPED}
    tbody
    tr:nth-child(odd) {
    background: ${(props) => props.theme.tableStrip} !important;
  }

  & table.${Classes.HTML_TABLE}.${Classes.INTERACTIVE} tbody tr:hover {
    background-color: ${(props) => props.theme.tableRowHover} !important;
  }
`;

const StyledEmptyBoxNonIdealState = styled(NonIdealState)`
  border: solid 1px rgba(16, 22, 26, 0.15);
  border-top: none;
  padding-bottom: 5rem !important;
`;

export function CustomDataTable<T>(props: Readonly<ICustomDataTableProps<T>>) {
  const { searched, renderNoDataEmpty = false } = props;
  const { t, tUnsafe, theme } = useCommonHooks();

  const noDataContent = React.useMemo(() => {
    if (renderNoDataEmpty) {
      return <StyledEmptyBoxNonIdealState />;
    }

    if (searched || searched === undefined) {
      return (
        <StyledNonIdealState icon="search" title={t(ETLCodes.GeneralNoData)} />
      );
    }

    return (
      <StyledNonIdealState
        icon="series-search"
        title={t(ETLCodes.PleaseSelectCriteria)}
      />
    );
  }, [renderNoDataEmpty, searched, t]);

  return (
    <StyledDataTable2<any>
      theme={theme}
      htmlTableOptions={{
        bordered: false,
        condensed: true,
        striped: true,
      }}
      showColumnSelector={false}
      filterMode="OnEnter"
      translateFunc={tUnsafe}
      {...props}
      renderNoData={noDataContent}
    />
  );
}
