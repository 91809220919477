import { Colors } from "@blueprintjs/core";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-width: 255px;
  height: auto;
  /* Change color for customer */
  /* background-color: ${(props) =>
    props.color ? props.color : Colors.DARK_GRAY4} !important; */

  & .bp3-menu {
    padding: 0 !important;
  }
`;
