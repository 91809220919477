import React from "react";
import { Navigate, Route, Routes } from "react-router";

import { FullPageLoader } from "./components";
import CustomerItemPage from "./pages/customers/item/CustomerItemPage";
import { UnauthorizedPage } from "./pages/common";
import { useAuth } from "./contexts";
import { ActionCode } from "./api";

export enum ERoutes {
  translations = "/translations",
  dataTranslations = "/dataTranslations",
  notFound = "/notFound",
  unauthorized = "/unauthorized",
  users = "/users",
  home = "/",
  actionRoles = "/actionRoles",
  deliveryOrders = "/deliveryOrders",
  customers = "/customers",
}

const getEditRoute = (base: ERoutes) => "" + base + "/:id/:tab/:state?";

const UserItemPage = React.lazy(
  () => import("./pages/users/item/userItemPage")
);
const UserListPage = React.lazy(
  () => import("./pages/users/list/userListPage")
);
const TranslationEditPage = React.lazy(
  () => import("./pages/translation/TranslationEditPage")
);
const DataTranslationEditPage = React.lazy(
  () => import("./pages/translation/DataTranslationEditPage")
);
const ActionRoleListPage = React.lazy(
  () => import("./pages/actionRoles/list/ActionRoleListPage")
);

const DeliveryOrderListPage = React.lazy(
  () => import("./pages/deliveryOrders/list/DeliveryOrderListPage")
);

const CustomerListPage = React.lazy(
  () => import("./pages/customers/CustomerListPage")
);

export const AppRouter = () => {
  const { hasAction } = useAuth();
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route
          path="*"
          element={<Navigate to={ERoutes.deliveryOrders} replace />}
          action={hasAction([ActionCode.ViewDeliveryOrders])}
        />
        <Route
          path={`${ERoutes.translations}`}
          element={<TranslationEditPage />}
          action={hasAction([ActionCode.EditTranslations])}
        />
        <Route
          path={`${ERoutes.dataTranslations}`}
          element={<DataTranslationEditPage />}
          action={hasAction([ActionCode.EditDataTranslations])}
        />
        <Route
          path={ERoutes.users}
          element={<UserListPage />}
          action={hasAction([ActionCode.ViewUser])}
        />
        <Route
          path={getEditRoute(ERoutes.users)}
          element={<UserItemPage />}
          action={hasAction([ActionCode.EditUser])}
        />

        <Route
          path={ERoutes.actionRoles}
          element={<ActionRoleListPage />}
          action={hasAction([ActionCode.ViewUserActions])}
        />

        <Route
          path={ERoutes.deliveryOrders}
          element={<DeliveryOrderListPage />}
          action={hasAction([ActionCode.ViewDeliveryOrders])}
        />
        <Route
          path={getEditRoute(ERoutes.customers)}
          element={<CustomerItemPage />}
          action={hasAction([ActionCode.EditCustomer])}
        />
        <Route
          path={ERoutes.customers}
          element={<CustomerListPage />}
          action={hasAction([ActionCode.ViewCustomer])}
        />

        <Route
          path={`${ERoutes.unauthorized}`}
          element={<UnauthorizedPage />}
        />
      </Routes>
    </React.Suspense>
  );
};
