import { lowerFirst } from "lodash";
import { showError, ValidationErrorsType } from "nsitools-react";
import React from "react";

import { ResultError } from "../api";
import { useTl } from "./useTl";

export const GlobalErrorField = "_GLOBAL_";

export function useManageValidationErrors() {
  const { tUnsafe } = useTl();

  const getErrorMessageDisplayed = React.useCallback(
    (error: ResultError, showMessage: boolean) => {
      if (showMessage) {
        return `${tUnsafe("ServerValidationError_" + error.errorCode)}${
          " - " + error.errorMessage
        }`;
      } else {
        return `${tUnsafe("ServerValidationError_" + error.errorCode)}`;
      }
    },
    [tUnsafe]
  );

  const parseValidationErrors = React.useCallback(
    (error: any, rootKey = "", showMessage = false): ValidationErrorsType => {
      let object: Array<any>;
      if (Array.isArray(error)) {
        object = error;
      } else {
        if (!error.response) {
          return {} as ValidationErrorsType;
        }
        object = JSON.parse(error.response);
      }

      if (Array.isArray(object)) {
        return object.map(ResultError.fromJS).reduce((curr, next) => {
          if (next.errorCode) {
            let propertyName = next.propertyName;
            if (propertyName === "") {
              propertyName = GlobalErrorField;
            }
            if (rootKey !== "")
              propertyName = propertyName.replace(rootKey + ".", "");
            propertyName = lowerFirst(propertyName);

            let existingErrors = [];

            if (Object.prototype.hasOwnProperty.call(curr, propertyName))
              existingErrors = curr[propertyName];

            curr[propertyName] = [
              ...existingErrors,
              next.errorCode
                ? getErrorMessageDisplayed(next, showMessage)
                : next.errorMessage,
            ];
          }
          return curr;
        }, {} as ValidationErrorsType);
      }
      return {} as ValidationErrorsType;
    },
    [getErrorMessageDisplayed]
  );

  const notifyGlobalError = React.useCallback(
    (error: any, rootKey = "", showMessage: boolean = false) => {
      const errors = parseValidationErrors(error, rootKey, showMessage);
      if (errors[GlobalErrorField]) {
        showError(
          <ul>
            {errors[GlobalErrorField].map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>,
          {
            timeout: 0,
          }
        );
      }
    },
    [parseValidationErrors]
  );
  return {
    parseValidationErrors,
    notifyGlobalError,
  };
}
