import { useParams } from "react-router-dom";

export type EditState = "view" |"edit";
export function useEditRouteParams() {
  const { id, tab, state, ...other } = useParams<{
    id: string;
    tab: string;
    state:EditState
  }>();

  return {
    id,
    tab,
    state,
    other,
  };
}
