import nsiLogo from "./nsi-logo.png";
import nsiLogoWhite from "./nsi_logo_white.png";
import placeholderPerson from "./placeholder-person.png";
import placeholderImage from "./image_placeholder.png";
import { baseUrl } from "../apiConfig";
import ccbLogo from "./ccb-logo.png";

export function autoPrefixUrls(s: string) {
  if (!s.includes("data:image")) {
    return baseUrl + s;
  } else {
    return s;
  }
}

const assets = {
  images: {
    ccbLogo: autoPrefixUrls(ccbLogo),
    nsiLogo: autoPrefixUrls(nsiLogo),
    nsiLogoWhite: autoPrefixUrls(nsiLogoWhite),
    placeholderPerson: autoPrefixUrls(placeholderPerson),
    placeholderImage: autoPrefixUrls(placeholderImage),
  },
};

export default assets;
