import { Card, Collapse, Colors, Icon, MaybeElement } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import classNames from "classnames";
import { FlexFieldGroup, IFlexFieldGroupProps } from "nsitools-react";
import React from "react";
import styled from "styled-components";

import { useCommonHooks } from "../../hooks";
import { IThemeData } from "../../theme";

export interface CardFieldGroupProps
  extends Omit<IFlexFieldGroupProps, "wrapper"> {
  withCard?: boolean;
  title?: string;
  rightElement?: React.ReactNode;
  icon?: IconName | MaybeElement;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  className?: string;
  cardStyle?: React.CSSProperties;
  showBorderBottom?: boolean;
}

const StyledCard = styled(Card)<{ theme: IThemeData }>`
  background: rgb(255, 255, 255);

  flex: 1;
  padding: 0.5rem;

  /* padding: 1rem; */
  /* height: calc(100% - 1rem); COMMENTED TO FIT CONTENT */

  & .card-fieldset {
  }

  & .card-title {
    font-weight: 600;
    //font-size: large;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.titleColor} !important;

    & > * + * {
      margin-left: 0.5rem;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  /* border-bottom: 1px solid ${Colors.LIGHT_GRAY3}; */
  margin-bottom: 0.5rem;
  &.collapsible {
    cursor: pointer;
  }
  //border-bottom: 1px solid lightgrey;
`;

export const CardFieldGroup: React.FunctionComponent<CardFieldGroupProps> = ({
  // fieldsetProps,
  withCard = true,
  title,
  rightElement,
  icon,
  collapsible = false,
  defaultCollapsed = false,
  className,
  cardStyle,
  showBorderBottom = true,
  ...props
}) => {
  const { theme } = useCommonHooks();

  const showTitleBar = React.useMemo(
    () => title || rightElement,
    [rightElement, title]
  );

  const [collapseOpen, setCollapseOpen] = React.useState(!defaultCollapsed);

  return (
    <FlexFieldGroup
      wrapper={
        withCard
          ? (c) => (
              <StyledCard theme={theme} className={className} style={cardStyle}>
                {showTitleBar && (
                  <TitleContainer
                    onClick={(e: any) => {
                      const className = e.target.className;
                      if (
                        typeof className === "string" &&
                        className.includes("collapsible")
                      ) {
                        e.stopPropagation();
                        setCollapseOpen((prev) => !prev);
                      }
                    }}
                    style={{
                      borderBottom: showBorderBottom
                        ? "1px solid lightgrey"
                        : "none",
                    }}
                    className={classNames({ collapsible: collapsible })}
                  >
                    <div className="card-title ">
                      {icon && (
                        <Icon
                          className={classNames({ collapsible: collapsible })}
                          icon={icon}
                        />
                      )}
                      <span
                        className={classNames({ collapsible: collapsible })}
                      >
                        {title}
                      </span>
                    </div>
                    <div>
                      {rightElement}
                      {collapsible && (
                        <Icon
                          icon={collapseOpen ? "chevron-up" : "chevron-down"}
                          color={theme.primaryColor}
                          onClick={() => setCollapseOpen((prev) => !prev)}
                        />
                      )}
                    </div>
                  </TitleContainer>
                )}
                <Collapse isOpen={collapseOpen} keepChildrenMounted>
                  {c}
                </Collapse>
              </StyledCard>
            )
          : null
      }
      gap="0.5rem"
      {...props}
    />
  );
};
