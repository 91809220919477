import { Boundary, BreadcrumbsProps, Classes } from "@blueprintjs/core";
import { Breadcrumbs2 } from "@blueprintjs/popover2";
import React from "react";
import { IThemeData } from "../theme";
import styled from "styled-components";
import { useCommonHooks } from "../hooks";

interface IAppBreadcrumbProps extends Pick<BreadcrumbsProps, "items"> {}

const StyledBreadCrumb = styled(Breadcrumbs2)<{ theme: IThemeData }>`
  background-color: ${(props) =>
    props.theme.breadCrumbsColor || "none"} !important;
  padding-left: 1rem;

  & .${Classes.BREADCRUMB} {
    color: ${(props) => props.theme.breadCrumbsTextColor || ""} !important;
    &:hover {
      color: ${(props) => props.theme.tabTextHover} !important;
    }
  }
`;

export const AppBreadcrumb: React.FunctionComponent<IAppBreadcrumbProps> = ({
  items,
}) => {
  const { theme } = useCommonHooks();

  React.useEffect(() => {
    if (items) {
      document.title =
        items
          .filter((i) => !!i.text && typeof i.text === "string")
          .map((i) => i.text)
          .join(" > ") + " - CCB Portal Granulat";
    } else {
      document.title = "CCB Portal Granulat";
    }
  }, [items]);
  return (
    <StyledBreadCrumb
      items={items}
      theme={theme}
      collapseFrom={Boundary.START}
    />
  );
};
