import React from "react";
import * as Yup from "yup";
import { ETLCodes } from "../../locales";
import { useTl } from "../useTl";

export const useValidators = () => {
  const { t } = useTl();

  const dataTranslationValidator = React.useMemo(
    () =>
      Yup.object().shape({
        dataTranslationValues: Yup.array().of(
          Yup.object().shape({
            text: Yup.string()
              .nullable()
              .when("languageCode", {
                is: "FR",
                then: Yup.string().nullable().required(t(ETLCodes.Required)),
                otherwise: Yup.string().nullable(),
              })
              .when("languageCode", {
                is: "NL",
                then: Yup.string().nullable().required(t(ETLCodes.Required)),
                otherwise: Yup.string().nullable(),
              })
              .when("languageCode", {
                is: "EN",
                then: Yup.string().nullable().required(t(ETLCodes.Required)),
              })
              .when("languageCode", {
                is: "DE",
                then: Yup.string().nullable().required(t(ETLCodes.Required)),
                otherwise: Yup.string().nullable(),
              }),
          })
        ),
      }),
    [t]
  );

  return {
    dataTranslationValidator,
  };
};
