import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

import { ETLCodes } from "./ETLCodes";

(window as any).MISSING_TL_KEYS = {};
(window as any).GetMissingKeysEnum = () => {
  const s = Object.keys((window as any).MISSING_TL_KEYS).join(",\n");
  console.log("," + s);
  return s;
};

export const initTranslator = (baseUrl: string) => {
  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      lng: "en",
      fallbackLng: "en",
      react: {
        useSuspense: true,
      },
      backend: {
        loadPath: `${baseUrl}/api/translation/?languageCode={{lng}}`,
        addPath: `${baseUrl}/api/translation/{{lng}}`,
        parse: (data: string) => {
          const parsed = JSON.parse(data);
          const reduced: object = parsed.reduce((prev, curr) => {
            prev[curr.translationCode] = curr.text;
            return prev;
          }, {});
          return reduced;
        },
        parsePayload: function (namespace, key, fallbackValue) {
          return {
            text: fallbackValue,
            translationCode: key,
          };
        },
      },
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      parseMissingKeyHandler: (key) => {
        (window as any).MISSING_TL_KEYS[key] = true;
        return `${key}_MISSING_TL`;
      },
      saveMissing: true,
    });
};

export const tlCodesArray = Object.keys(ETLCodes)
  .filter((k) => typeof ETLCodes[k as any] === "number")
  .sort((a, b) => a.localeCompare(b));

export { ETLCodes };
