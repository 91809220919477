import React from "react";
import styled from "styled-components";
import { Spinner, SpinnerSize } from "@blueprintjs/core";

export interface IFullPageLoaderProps {}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
`;

export const FullPageLoader: React.FunctionComponent<
  IFullPageLoaderProps
> = () => {
  return (
    <Container>
      <Spinner size={SpinnerSize.LARGE} />
    </Container>
  );
};
