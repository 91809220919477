import * as React from "react";
import {} from "@blueprintjs/core";
import { FGSelectInput, IFGMultiSuggestInputProps } from "nsitools-react";
import { useCommonHooks } from "../../hooks";
import { useQuery } from "react-query";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";
import { ReferentialItemDto } from "../../api";

export interface CustomerTypeSelectProps
  extends Omit<
    IFGMultiSuggestInputProps<any>,
    "items" | "loading" | "displayField" | "valueField"
  > {}

export const CustomerTypeSelect: React.FunctionComponent<
  CustomerTypeSelectProps
> = ({ ...fgSelectInputProps }) => {
  const { t, apis } = useCommonHooks();

  const { data, isFetching } = useQuery("ref-customer-types", async () => {
    return await apis.referentials.customerTypes();
  });

  return (
    <FGSelectInput
      label={fgSelectInputProps.label || t(ETLCodes.CustomerType)}
      items={data}
      loading={isFetching}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      isFilterable={false}
      {...fgSelectInputProps}
    />
  );
};

export default CustomerTypeSelect;
