import { Button } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { IThemeData } from "../../theme";
import { ICustomButtonProps } from "./ICustomButtonProps";

const IconContainer = styled.span<{ theme: IThemeData }>`
  height: 40px;
  width: 40px;
  background-color: ${(props) => props.theme.userColor};
  color: ${(props) => props.theme.headerTextColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IUserButtonProps extends ICustomButtonProps {}

export const UserButton: React.FunctionComponent<IUserButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent,
  text,
  hideText = true,
  small,
}) => {
  const { fullUserName, t, theme } = useCommonHooks();

  const initials = React.useMemo(
    () =>
      fullUserName
        .split(" ")
        .map((w) => w[0])
        .join("")
        .toUpperCase(),
    [fullUserName]
  );
  return (
    <Button
      icon={<IconContainer theme={theme}>{initials}</IconContainer>}
      minimal={minimal}
      onClick={onClick}
      text={!hideText ? text || t(ETLCodes.User) : null}
      intent={intent}
      loading={loading}
      disabled={disabled}
      small={small}
    />
  );
};
