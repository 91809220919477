import {
  Alignment,
  AnchorButton,
  Classes,
  Collapse,
  Colors,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import { lighten, rgba } from "polished";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { useAuth, useNavigationContext } from "../../contexts";
import { useCommonHooks } from "../../hooks";
import { IThemeData } from "../../theme";
import { CollapseAllContext } from "./CollapseAllContext";
import { Container } from "./Container";
import { INavigationItem } from "./INavigationItem";

const ButtonStyled = styled(AnchorButton)<{ theme: IThemeData; level: number }>`
  /* Change colors for customer */
  margin: 0;
  border-radius: 0 !important;
  color: ${({ theme, level }) => {
    switch (level) {
      case 0:
        return theme.menuIconColor;
      case 1:
        return theme.menuColor;
      case 2:
        return theme.menuColor;
      default:
        return lighten(0.01 * level, theme.menuColor);
    }
  }} !important;
  line-height: 1.15;

  background-color: ${({ theme, level }) => {
    switch (level) {
      case 0:
        return theme.menuColor;
      case 1:
        return lighten(1, theme.menuColor);
      case 2:
        return lighten(0.6, theme.menuColor);
      default:
        return lighten(0.01 * level, theme.menuIconColor);
    }
  }} !important;

  padding-left: ${({ level }) => 1 + level * 0.7}em !important;

  &:hover {
    background-color: ${({ theme, level }) => {
      switch (level) {
        case 0:
          return theme.menuIconColor;
        case 1:
          return theme.menuColor;
        case 2:
          return theme.menuColor;
        default:
          return lighten(0.01 * level, theme.menuColor);
      }
    }} !important;
    color: ${({ theme, level }) => {
      switch (level) {
        case 0:
          return theme.menuColor;
        case 1:
          return lighten(1, theme.menuColor);
        case 2:
          return lighten(0.6, theme.menuColor);
        default:
          return lighten(0.01 * level, theme.menuIconColor);
      }
    }} !important;

    & .${Classes.ICON} {
      color: ${({ theme, level }) => {
        switch (level) {
          case 0:
            return theme.menuColor;
          case 1:
            return lighten(1, theme.menuColor);
          case 2:
            return lighten(0.6, theme.menuColor);
          default:
            return lighten(0.01 * level, theme.menuIconColor);
        }
      }} !important;
    }
  }

  & .${Classes.ICON} {
    color: ${({ theme, level }) => {
      switch (level) {
        case 0:
          return theme.menuIconColor;
        case 1:
          return theme.menuColor;
        case 2:
          return theme.menuColor;
        default:
          return lighten(0.01 * level, theme.menuColor);
      }
    }} !important;
  }

  &.is-active {
    text-decoration: blink !important;
    border-color: ${(props) =>
      props.theme.menuIconColor || Colors.BLUE3} !important;
    background-image: linear-gradient(
      to right,
      ${(props) => rgba(props.theme.menuColor, 0.8)},
      ${(props) => rgba(props.theme.menuIconColor, 0.8)}
    ) !important;
    color: white !important;

    & .${Classes.ICON} {
      color: white !important;
    }
  }

  &:focus {
    outline: none;
  }
`;

export interface IMenuItemProps {
  item: INavigationItem;
  level: number;
  disabled?: boolean;
  maxAutoCollapseLevel?: number;
  onNavigation?: () => void;
}

export const MenuItem: React.FunctionComponent<IMenuItemProps> = ({
  item,
  level,
  disabled,
  maxAutoCollapseLevel = 999,
  onNavigation,
}) => {
  const { theme, router } = useCommonHooks();
  const isActive = React.useMemo(
    () => item.route && router.pathname.startsWith(item.route),
    [item, router]
  );

  const [isExpanded, setIsExpanded] = React.useState(!!item.defaultOpened);
  const { t } = useCommonHooks();
  const id = React.useMemo(() => uuidv4(), []);
  const { hasAction } = useAuth();
  const { openedMenuId, setMenuOpenedId } =
    React.useContext(CollapseAllContext);

  const { clearBackRoute } = useNavigationContext();

  React.useEffect(() => {
    const hasItemWithCurrentRoute = (itm: INavigationItem) => {
      if (itm.route && router.pathname.includes(itm.route)) {
        return true;
      }
      if (itm.items) {
        return itm.items.filter((i) => hasItemWithCurrentRoute(i)).length > 0;
      }
      return false;
    };

    if (hasItemWithCurrentRoute(item)) {
      setIsExpanded(true);
      setMenuOpenedId({ level, id });
    }
  }, [id, item, level, router.pathname, setMenuOpenedId]);

  React.useEffect(() => {
    if (
      openedMenuId?.level === level &&
      openedMenuId?.id !== id &&
      level <= maxAutoCollapseLevel
    ) {
      setIsExpanded(false);
    }
  }, [id, level, maxAutoCollapseLevel, openedMenuId]);

  const hasChildren = React.useMemo(() => {
    return item.items && item.items.length > 0;
  }, [item]);

  const collapseIcon = React.useMemo(() => {
    if (hasChildren) {
      return isExpanded ? IconNames.CHEVRON_RIGHT : IconNames.CHEVRON_DOWN;
    } else {
      return IconNames.BLANK;
    }
  }, [hasChildren, isExpanded]);

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (hasChildren) {
        if (!isExpanded) {
          setMenuOpenedId({ level, id });
        }
        setIsExpanded((e) => !e);
      } else if (item.route) {
        if (event.ctrlKey || event.shiftKey) return;
        clearBackRoute();
        router.push(item.route);
        if (onNavigation) {
          onNavigation();
        }
      }
    },
    [
      hasChildren,
      item.route,
      isExpanded,
      setMenuOpenedId,
      level,
      id,
      clearBackRoute,
      router,
      onNavigation,
    ]
  );

  const finalSubItems = React.useMemo(() => {
    if (!item.items) return [];
    return item.items.filter((d) => hasAction(d.actions));
  }, [hasAction, item.items]);

  if (finalSubItems.length === 0 && hasChildren) {
    return null;
  }

  return (
    <>
      {item.startGroupTitle && (
        <div style={{ color: "red" }}>{item.startGroupTitle}</div>
      )}
      {item.route ? (
        <Link to={item.route} style={{ width: "100%" }}>
          <ButtonStyled
            style={{ width: "100%" }}
            theme={theme}
            level={level}
            text={t(item.name)}
            icon={
              item.alternativeIcon ? (
                <i className={`${Classes.ICON} ${item.alternativeIcon}`} />
              ) : (
                item.icon
              )
            }
            rightIcon={collapseIcon}
            onClick={onClick}
            minimal={true}
            className={classnames([
              { "has-children": hasChildren, "is-active": isActive },
            ])}
            alignText={Alignment.LEFT}
            small={false}
            disabled={disabled}
            href={item.route ? `#${item.route}` : undefined}
          />
        </Link>
      ) : (
        <ButtonStyled
          theme={theme}
          level={level}
          text={t(item.name)}
          icon={
            item.alternativeIcon ? (
              <i className={`${Classes.ICON} ${item.alternativeIcon}`} />
            ) : (
              item.icon
            )
          }
          rightIcon={collapseIcon}
          onClick={onClick}
          minimal={true}
          className={classnames([
            { "has-children": hasChildren, "is-active": isActive },
          ])}
          alignText={Alignment.LEFT}
          small={false}
          disabled={disabled}
          href={item.route ? `#${item.route}` : undefined}
        />
      )}
      <Collapse isOpen={isExpanded}>
        <Container>
          {finalSubItems.map((itm) => (
            <MenuItem
              item={itm}
              key={`${itm.name}_${itm.route}`}
              level={level + 1}
              disabled={itm.disabled}
              maxAutoCollapseLevel={maxAutoCollapseLevel}
              onNavigation={onNavigation}
            />
          ))}
        </Container>
      </Collapse>
    </>
  );
};
