import * as React from "react";
import { Spinner, SpinnerSize, Tab, Tabs } from "@blueprintjs/core";
import { useCommonHooks, useEditRouteParams } from "../../../hooks";
import { useQuery } from "react-query";
import { ETLCodes } from "../../../locales";
import { ERoutes } from "../../../AppRouter";
import { BackButton, PageBase } from "../../../components";
import CustomerDetailPanel from "./CustomerDetailPanel";

export interface CustomerItemPageProps {}

export const CustomerItemPage: React.FunctionComponent<
  CustomerItemPageProps
> = () => {
  const { t, router, apis } = useCommonHooks();
  const { id, tab } = useEditRouteParams();

  const { data: displayName, isFetching: loadingDisplayName } = useQuery(
    ["customer-display-name", id],
    async () => {
      if (+id > 0) {
        const displayName = await apis.customers.getDisplayName(+id);
        return displayName?.text;
      }
      return t(ETLCodes.NewCustomer);
    }
  );

  const breadCrumbs = React.useMemo(() => {
    return [
      { text: t(ETLCodes.Customers), route: ERoutes.customers },
      {
        text: loadingDisplayName ? (
          <Spinner size={SpinnerSize.SMALL} />
        ) : (
          displayName
        ),
      },
    ];
  }, [displayName, loadingDisplayName, t]);

  return (
    <PageBase breadCrumbs={breadCrumbs}>
      <Tabs
        selectedTabId={tab}
        onChange={(nextTabId) =>
          router.push(`${ERoutes.customers}/${id}/${nextTabId}`)
        }
      >
        <BackButton backRoute={ERoutes.customers} />
        <Tab
          id="details"
          title={t(ETLCodes.Details)}
          panel={<CustomerDetailPanel customerId={+id} />}
        />
      </Tabs>
    </PageBase>
  );
};

export default CustomerItemPage;
