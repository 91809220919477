import { useQuery } from "react-query";
import { useApiService } from "../useApiService";
import { ReferentialApi } from "../../api";

export function useLanguagesRef() {
  const api = useApiService(ReferentialApi);
  const { data, isFetching, refetch } = useQuery(
    "ref-language",
    async () => {
      return api.languages();
    },
    { staleTime: Infinity }
  );

  return {
    languages: data,
    languagesLoading: isFetching,
    refetchLanguagesRef: refetch,
  };
}
