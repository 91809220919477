import { Classes, Colors, Icon, Menu } from "@blueprintjs/core";
import { ToolTipButton } from "nsitools-react";
import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import { useAuth } from "../../contexts";
import { useCommonHooks, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { IThemeData } from "../../theme";
import { CollapseAllContext, OpenedMenuId } from "./CollapseAllContext";
import { Container } from "./Container";
import { INavigationItem } from "./INavigationItem";
import { IMenuItemProps, MenuItem } from "./MenuItem";
import { QuickAccess } from "./QuickAccess";

export const NAVIGATION_MENU_WIDTH = 280;

interface INavigationMenuProps extends Pick<IMenuItemProps, "onNavigation"> {
  navigationMenu: INavigationItem[];
  isFixed: boolean;
  changeFixed: (value: boolean) => void;
  collapseOtherOnSelection?: boolean;
  maxAutoCollapseLevel?: number;
  enableQuickAccess?: boolean;
}

const MenuStyled = styled(Menu)<{ theme: IThemeData }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  border-radius: 0;
  min-width: ${NAVIGATION_MENU_WIDTH}px;
  background-color: ${(props) =>
    props.theme.menuColor || Colors.DARK_GRAY4} !important;
`;

const TitleContainer = styled.div<{ theme: IThemeData }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) =>
    props.theme.menuColor ||
    Colors.DARK_GRAY4} !important; /* Change for customer */
  align-items: center;
`;

export const NavigationMenu: React.FunctionComponent<INavigationMenuProps> = ({
  navigationMenu,
  isFixed,
  changeFixed,
  collapseOtherOnSelection: closeOtherOnSelection,
  maxAutoCollapseLevel,
  onNavigation,
  enableQuickAccess = true,
}) => {
  const { theme } = useCommonHooks();
  const { t } = useTl();
  const [openedMenuId, setOpenedMenuId] = React.useState<OpenedMenuId | null>(
    null
  );
  const { pathname } = useLocation();
  const { hasAction } = useAuth();

  const checkOpened = React.useCallback(
    (navItem: INavigationItem): INavigationItem => {
      let opened = false;
      if (navItem?.route && pathname.startsWith(navItem.route)) {
        opened = true;
      }
      const nextNavItems = navItem?.items ? navItem.items.map(checkOpened) : [];
      opened = opened || nextNavItems.filter((d) => d.defaultOpened).length > 0;
      return {
        ...navItem,
        defaultOpened: opened,
        items: nextNavItems,
      };
    },
    [pathname]
  );

  const navigationMenuFinal = React.useMemo(() => {
    // Keep menu original state for root route
    // if (pathname === "/") return navigationMenu;
    return navigationMenu
      .filter((nm) => {
        const actionOk = hasAction(nm.actions || []);
        return nm.actions ? actionOk : true;
      })
      .map(checkOpened);
  }, [checkOpened, hasAction, navigationMenu]);

  const getCollapseAllValue = React.useMemo(() => {
    return {
      openedMenuId,
      setMenuOpenedId: closeOtherOnSelection ? setOpenedMenuId : () => {},
    };
  }, [closeOtherOnSelection, openedMenuId]);

  return (
    <Container theme={theme}>
      <TitleContainer theme={theme}>
        <div
          className={Classes.DRAWER_HEADER}
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: theme.highlightColor,
            }}
          >
            <div>{t(ETLCodes.Menu)}&nbsp;</div>
            <ToolTipButton
              tooltip={
                isFixed
                  ? t(ETLCodes.UnFixMenuTooltip)
                  : t(ETLCodes.FixMenuTooltip)
              }
              icon={
                <Icon
                  icon={isFixed ? "unpin" : "pin"}
                  color={theme.highlightColor}
                />
              }
              minimal={true}
              onClick={() => changeFixed(!isFixed)}
            />
          </div>
        </div>
      </TitleContainer>
      <CollapseAllContext.Provider value={getCollapseAllValue}>
        {enableQuickAccess && (
          <QuickAccess
            navigationMenu={navigationMenu}
            onNavigation={onNavigation}
          />
        )}
        <MenuStyled large={false} theme={theme}>
          {navigationMenuFinal.map((n) => (
            <MenuItem
              item={n}
              key={`${n.name}_${n.route}`}
              level={0}
              disabled={n.disabled}
              maxAutoCollapseLevel={maxAutoCollapseLevel}
              onNavigation={onNavigation}
            />
          ))}
        </MenuStyled>
      </CollapseAllContext.Provider>
    </Container>
  );
};
