import i18n from "i18next";
import React from "react";
import { BaseAPI, Configuration } from "../api";

import { useAuth } from "../contexts";
import { useRouter } from "./useRouter";
import { ERoutes } from "../AppRouter";

export let baseUrl =
  (document.getElementById("_baseUrl") as HTMLInputElement)?.value || "~";

if (baseUrl === "~") {
  const hostUrl = window.location.href.split("/");
  baseUrl = hostUrl[0] + "//" + hostUrl[2];
}

export function useApiService<T extends BaseAPI>(
  type: new (configuration: Configuration) => T,
  transformRequest?: (request: RequestInit) => RequestInit
): T {
  const { getAccessToken } = useAuth();
  const router = useRouter();

  const getAccessTokenBearer = React.useCallback(async () => {
    return await getAccessToken();
  }, [getAccessToken]);

  const configuration = React.useMemo(() => {
    const cfg: Configuration = {
      basePath: baseUrl,
      apiKeyPromise: getAccessTokenBearer,
      headers: {
        "x-user-language": i18n.language,
      },
      middlewares: [
        {
          pre(request) {
            const result = transformRequest
              ? transformRequest(request)
              : request;
            return Promise.resolve(result);
          },
          post(response) {
            if (response.status === 401 || response.status === 403) {
              router.replace(ERoutes.unauthorized);
            }
            return Promise.resolve(response);
          },
        },
      ],
    };

    return cfg;
  }, [getAccessTokenBearer, transformRequest, router]);
  return React.useMemo(() => new type(configuration), [configuration, type]);
}
