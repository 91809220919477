import { FGSelectInput, IFGSelectInputProps } from "nsitools-react";
import React from "react";
import { ReferentialItemDto } from "../../api";
import { useCommonHooks, useLanguagesRef } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";

export interface ILanguageSelectProps
  extends Pick<
    IFGSelectInputProps<any>,
    "requiredMark" | "visible" | "disabled" | "style"
  > {
  name: string;
  label?: string;
  loading?: boolean;
}

export const LanguageSelect: React.FunctionComponent<ILanguageSelectProps> = ({
  name,
  label,
  loading = false,
  ...fgSelectInputProps
}) => {
  const { t } = useCommonHooks();
  const { languages, languagesLoading } = useLanguagesRef();

  return (
    <FGSelectInput
      label={label || t(ETLCodes.Language)}
      name={name}
      items={languages}
      loading={languagesLoading || loading}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      isFilterable={true}
      {...fgSelectInputProps}
    />
  );
};
