import { Button, Classes, Intent } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { ICustomButtonProps } from "./ICustomButtonProps";

const IconSpan = styled.span`
  font-weight: bolder;
  font-size: 11pt;
`;

interface IEmailProps extends Omit<ICustomButtonProps, "onClick"> {
  email: string;
  noText?: boolean;
}

export const EmailButton: React.FunctionComponent<IEmailProps> = ({
  minimal = true,
  email,
  loading,
  disabled,
  intent = Intent.NONE,
  noText = false,
}) => {
  const onClickMail = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const el = document.createElement("a");
      el.setAttribute("href", `mailto:${email}`);
      el.click();
    },
    [email]
  );

  return (
    <Button
      title={email}
      intent={intent}
      disabled={disabled}
      icon={<IconSpan className={Classes.ICON}>@</IconSpan>}
      minimal={minimal}
      loading={loading}
      onClick={onClickMail}
      type="button"
      text={noText ? null : email}
    />
  );
};
