import { Button } from "@blueprintjs/core";
import React from "react";

import { ERoutes } from "../../AppRouter";
import { useNavigationContext } from "../../contexts";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import styled from "styled-components";
import { IThemeData } from "../../theme";

interface IBackButtonProps {
  backRoute: ERoutes | string;
}

const StyledButton = styled(Button)<{ theme: IThemeData }>`
  margin-bottom: 0.5rem !important;
  /* background: transparent !important; */
  /* color: white !important; */
`;

export const BackButton: React.FunctionComponent<IBackButtonProps> = ({
  backRoute,
}) => {
  const { t } = useTl();
  const { goBack } = useNavigationContext();

  return (
    <StyledButton
      title={`${t(ETLCodes.GoBack)}`}
      icon="arrow-left"
      intent="primary"
      minimal={true}
      onClick={() => goBack(backRoute)}
    />
  );
};
