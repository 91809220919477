import { Card, Classes, Colors, Spinner } from "@blueprintjs/core";
import { DataTable2, FGCustomInput, IDataTable2Props } from "nsitools-react";
import React from "react";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import styled from "styled-components";
import { IThemeData } from "../../theme";
import { formatDate } from "../../utils/formatDate";

export interface FormDataTableProps<T>
  extends Omit<
    IDataTable2Props<T>,
    | "htmlTableOptions"
    | "showColumnSelector"
    | "translateFunc"
    | "showPageSizeSelector"
  > {
  label?: string;
}

const NoDataContainer = styled.div<{ theme: IThemeData }>`
  margin: 0.5rem;
  padding: 0.25rem;
  font-style: italic;
  color: ${Colors.GRAY4};
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;

  td {
    vertical-align: middle !important;
  }

  &
    table.${Classes.HTML_TABLE}.${Classes.HTML_TABLE_STRIPED}
    tbody
    tr:nth-child(odd)
    td {
    background: ${(props) => props.theme.tableStrip} !important;
  }

  & table.${Classes.HTML_TABLE}.${Classes.INTERACTIVE} tbody tr:hover td {
    background-color: ${(props) => props.theme.tableRowHover} !important;
  }
`;

export function FormDataTable<T>(props: Readonly<FormDataTableProps<T>>) {
  const { tUnsafe, t, theme } = useCommonHooks();

  return (
    <FGCustomInput label={props.label}>
      {() => (
        <TableContainer theme={theme}>
          <DataTable2
            htmlTableOptions={{
              bordered: true,
              condensed: true,
              striped: true,
              interactive: !!props.onRowClick,
            }}
            showColumnSelector={false}
            translateFunc={tUnsafe}
            tableState={props.tableState}
            renderNoData={
              <NoDataContainer> {t(ETLCodes.GeneralNoData)}</NoDataContainer>
            }
            formatDate={formatDate}
            customLoadingComponent={
              <Card
                elevation={4}
                style={{
                  padding: "0.25rem",
                  display: "flex",
                }}
              >
                <Spinner size={16} />
                &nbsp;{t(ETLCodes.GeneralLoading)}
              </Card>
            }
            {...props}
          />
        </TableContainer>
      )}
    </FGCustomInput>
  );
}
