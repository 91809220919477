import * as React from "react";
import styled from "styled-components";
import { useFGContext } from "nsitools-react";
import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { ETLCodes } from "../../locales";
import { useTl } from "../../hooks";
import { useBlocker } from "react-router";
import { useEffect } from "react";

export interface FGDirtyRouteCheckerProps {
  ignoreDirtyRouteCheck?: boolean;
}

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

let SKIP_DIRTY_CHECK = false;
export const enableSkipDirtyCheck = () => (SKIP_DIRTY_CHECK = true);
export const clearSkipDirtyCheck = () => (SKIP_DIRTY_CHECK = false);

export const FGRouteDirtyChecker: React.FunctionComponent<
  FGDirtyRouteCheckerProps
> = ({ ignoreDirtyRouteCheck = false }) => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !ignoreDirtyRouteCheck &&
      !SKIP_DIRTY_CHECK &&
      formik.dirty &&
      currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("handlebeforeunload");
      if (!ignoreDirtyRouteCheck && !SKIP_DIRTY_CHECK && formik.dirty) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    // Add the event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [ignoreDirtyRouteCheck, formik.dirty, blocker.state]);

  return (
    <Dialog
      isOpen={blocker.state === "blocked"}
      title={t(ETLCodes.FormDirtyCancelTitle)}
      onClose={() => blocker.reset()}
    >
      <DialogBody>
        <p>{t(ETLCodes.FormDirtyUnsavedMessage)}</p>
        <p>{t(ETLCodes.FormDirtyCancelMessage)}</p>
      </DialogBody>

      <DialogFooter>
        <ActionContainer>
          <Button
            text={t(ETLCodes.FormDirtyCancelButton)}
            onClick={() => blocker.reset()}
          />
          <Button
            intent={"danger"}
            text={t(ETLCodes.FormDirtyLeaveButton)}
            onClick={() => blocker.proceed()}
          />
        </ActionContainer>
      </DialogFooter>
    </Dialog>
  );
};
