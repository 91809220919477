import { BreadcrumbProps, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { Loading } from "nsitools-react";
import React from "react";
import styled from "styled-components";

import { ERoutes } from "../AppRouter";
import { useCommonHooks } from "../hooks";
import { AppError } from "./AppError";

export interface CustomBreadcrumbProps extends Omit<BreadcrumbProps, "href"> {
  route?: string | ERoutes;
}

export interface PageBaseProps {
  breadCrumbs?: CustomBreadcrumbProps[];
  isLoading?: boolean;
  overflowMode?: "none" | "all" | "tabs-panel";
  stickyContent?: React.ReactNode;
  children: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  align-self: stretch;
  padding: 0.5rem;
  overflow: hidden;

  .${Classes.TABS}.${Classes.VERTICAL} > .${Classes.TAB_PANEL} {
    margin-top: 0;
    padding-left: 0.5rem;
  }

  .${Classes.TABS} > .${Classes.TAB_PANEL} {
    margin-top: 0.5rem;
  }
`;

const NonStickyContent = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.125rem;

  &.overflow {
    overflow: auto;
  }

  &.tab-panel-overflow {
    overflow: hidden;
    .${Classes.TABS} {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .${Classes.TABS} > .${Classes.TAB_PANEL} {
      overflow: auto;
    }
  }
`;

export const PageBase: React.FunctionComponent<PageBaseProps> = ({
  breadCrumbs,
  children,
  isLoading = false,
  overflowMode = "all",
  stickyContent = null,
}) => {
  const { setBaseBreadCrumb, setDetailBreadCrumb, router } = useCommonHooks();

  React.useEffect(() => {
    setDetailBreadCrumb([]);
  }, [setDetailBreadCrumb]);

  React.useEffect(() => {
    if (breadCrumbs) {
      const nextBr: BreadcrumbProps[] = breadCrumbs?.map((br) =>
        br.route
          ? {
              ...br,
              onClick: () => {
                if (br.route) {
                  router.push(br.route);
                }
              },
            }
          : { ...br }
      );
      setBaseBreadCrumb(nextBr);
    }
  }, [breadCrumbs, router, setBaseBreadCrumb]);

  return (
    <Container>
      {stickyContent && <div>{stickyContent}</div>}
      <NonStickyContent
        className={classNames({
          overflow: overflowMode === "all",
          "tab-panel-overflow": overflowMode === "tabs-panel",
        })}
      >
        <AppError>
          <Loading isLoading={isLoading}>{children}</Loading>
        </AppError>
      </NonStickyContent>
    </Container>
  );
};
