import React from "react";
import produce from "immer";

export type ApplicationEvents =
  | "LanguageChanged"
  | "RefreshSearchTables"
  | "ReloadTitlesTable"
  | "RefreshSearchObject"
  | "StatusChange"
  | "RefreshAcpConfigurationTree"
  | "RefreshClientSelect";

interface IEventsContext {
  dispatchEvent: (event: ApplicationEvents, data?: any) => void;
  subscribeToEvent: (event: ApplicationEvents, callback: CallBackType) => void;
  unsubscribeEvent: (event: ApplicationEvents, callback: CallBackType) => void;
}

const EventsContext = React.createContext<IEventsContext>(null);

interface EventsProviderProps {
  children: React.ReactNode;
}

type CallBackType = (data?: any) => void;

export const EventsProvider: React.FunctionComponent<EventsProviderProps> = ({
  children,
}) => {
  const [events, setEvents] = React.useState<{
    [event: string]: Array<CallBackType>;
  }>({});
  const dispatchEvent = React.useCallback(
    (event: string, data?: any) => {
      if (!events[event]) return;
      events[event].forEach((cb) => cb(data));
    },
    [events]
  );

  const subscribeToEvent = React.useCallback(
    (event: ApplicationEvents, callback: CallBackType) => {
      setEvents((prev) =>
        produce(prev, (draft) => {
          if (!draft[event]) {
            draft[event] = [];
          }
          draft[event].push(callback);
        })
      );
    },
    []
  );

  const unsubscribeEvent = React.useCallback(
    (event: ApplicationEvents, callback: CallBackType) => {
      setEvents((prev) =>
        produce(prev, (draft) => {
          if (draft[event]) {
            draft[event] = draft[event].filter((cb) => cb !== callback);
          }
        })
      );
    },
    []
  );

  const contextValue = React.useMemo(() => {
    return { dispatchEvent, subscribeToEvent, unsubscribeEvent };
  }, [dispatchEvent, subscribeToEvent, unsubscribeEvent]);

  return (
    <EventsContext.Provider value={contextValue}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEventsContext = () => React.useContext(EventsContext);
