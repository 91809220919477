import React from "react";
import { Button, Callout, Icon, Intent } from "@blueprintjs/core";
import styled from "styled-components";

export interface AppErrorProps {
  children: React.ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  & > * + * {
    margin-top: 1rem;
  }
`;

export class AppError extends React.Component<
  AppErrorProps,
  IErrorBoundaryState
> {
  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public componentDidCatch(error: Error, info: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Icon intent="danger" size={84} icon="error" />
          <Button
            text="Reload the page"
            onClick={() => window.location.reload()}
            intent="primary"
            icon="refresh"
          />
          <Callout
            intent={Intent.DANGER}
            title={this.state.error.message}
            style={{ textAlign: "left" }}
          >
            {this.state.error.stack}
          </Callout>
        </Container>
      );
    }

    return this.props.children;
  }
}
