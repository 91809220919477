import {
  Alignment,
  Classes,
  Icon,
  Menu,
  MenuItem,
  Navbar,
  Tag,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { useWindowSize } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { MenuButton, UserButton } from ".";
import { ERoutes } from "../AppRouter";
import { useCommonHooks, useDisplayLanguages } from "../hooks";
import { ETLCodes } from "../locales";
import {
  EnvironmentThemeData,
  getTheme,
  IThemeData,
  SiteTheme,
} from "../theme";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { LanguageSelector } from "./LanguageSelector";
import { UserMenu } from "./UserMenu";

interface IHeaderProps {
  showMenuIcon: boolean;
  toggleLeftMenu?: () => void;
  logoRightElement?: React.ReactNode;
}

const NavBarStyled = styled(Navbar)<{ theme: SiteTheme }>`
  position: relative;
  height: 50px !important;

  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ theme }) => getTheme(theme).headerColor} !important;
  color: ${({ theme }) => getTheme(theme).headerTextColor} !important;

  & > .${Classes.NAVBAR_GROUP} {
    height: 50px;
  }
`;
const LogoContainer = styled.div<{ theme: IThemeData }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => getTheme(theme).headerColor} !important;
  min-width: 270px;
  text-align: center;
  height: 100%;
`;
const HeaderText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const LogoImage = styled.img`
  height: 40px;
  border-radius: 5px;
  margin-left: 5px;
`;

const LanguageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => getTheme(theme).menuIconColor} !important;
`;

const ColoredTag = styled(Tag)<{ envColor: string }>`
  background-color: ${({ envColor }) => envColor} !important;
  color: #f6f7f9 !important;
  font-weight: bold;
`;

const StyledMenuItem = styled(MenuItem)``;

const EnvironmentName = ({
  environment,
}: {
  environment?: EnvironmentThemeData;
}) => {
  if (environment?.showEnvironment)
    return (
      <>
        <ColoredTag
          envColor={environment.environmentColor}
          icon={IconNames.Tag}
        >
          {environment.displayName.toUpperCase().substring(0, 3)}
        </ColoredTag>
        <Navbar.Divider />
      </>
    );
  return null;
};

export const Header: React.FC<IHeaderProps> = (props) => {
  const {
    theme,
    environmentTheme,
    router,
    t,
    logout,
    fullUserName,
    breadCrumbs,
  } = useCommonHooks();
  const { width } = useWindowSize();
  const [smallScreen, setSmallScreen] = React.useState(false);
  const { languages, changeLanguage, currentLanguage } = useDisplayLanguages();

  React.useEffect(() => {
    if (width) {
      setSmallScreen(width < 1400);
    }
  }, [width]);

  return (
    <div className={Classes.DARK} style={{ backgroundColor: "#2B2B2B" }}>
      <NavBarStyled theme={theme.siteTheme}>
        <div style={{ position: "relative" }}>
          <Navbar.Group align={Alignment.LEFT}>
            {props.toggleLeftMenu && props.showMenuIcon && (
              <>
                <MenuButton onClick={props.toggleLeftMenu} />
                <Navbar.Divider />
              </>
            )}
            <LogoContainer
              style={{}}
              // onClick={() => router.push(`${ERoutes.home}`)}
            >
              <LogoImage src={theme.logoUrl} alt="logo" />
              {props?.logoRightElement}
              <Navbar.Divider />
              <HeaderText>{theme.headerName}</HeaderText>
              <Navbar.Divider />
              <EnvironmentName environment={environmentTheme} />
            </LogoContainer>
          </Navbar.Group>
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>
              <AppBreadcrumb items={breadCrumbs} />
            </Navbar.Heading>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            {smallScreen ? (
              <Popover2
                usePortal={false}
                content={
                  <Menu>
                    <StyledMenuItem text={t(ETLCodes.Language)}>
                      {languages.map((lg) => (
                        <StyledMenuItem
                          key={lg.code}
                          text={
                            <LanguageContainer>
                              <span>{lg.label}</span>{" "}
                              {currentLanguage?.code === lg.code && (
                                <Icon icon="tick" />
                              )}
                            </LanguageContainer>
                          }
                          onClick={() => changeLanguage(lg.code)}
                        />
                      ))}
                    </StyledMenuItem>
                    <StyledMenuItem text={fullUserName}>
                      <StyledMenuItem
                        icon="log-out"
                        text={t(ETLCodes.Logout)}
                        onClick={() => logout()}
                      />
                    </StyledMenuItem>
                  </Menu>
                }
              >
                <UserButton />
              </Popover2>
            ) : (
              <>
                <LanguageSelector hideCurrentLanguage />
                <UserMenu />
              </>
            )}
          </Navbar.Group>
        </div>
      </NavBarStyled>
    </div>
  );
};
