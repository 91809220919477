import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  Intent,
  Panel,
  PanelStack2,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { InlineButtonContainer, usePrevious } from "nsitools-react";
import React from "react";
import styled from "styled-components";

import { CardFieldGroup } from ".";
import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";

const StyledDialog = styled(Dialog)`
  overflow: auto;
  padding-bottom: 0;
  max-height: 90vh;

  & .${Classes.DIALOG_BODY} {
    padding: 0.5rem;
  }
`;

const DialogBody = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.5rem;
  }
`;

const StyledPanelStack = styled(PanelStack2)<{ width: string; height: string }>`
  width: calc(
    ${(props) => (isNaN(+props.width) ? props.width : props.width + "px")} -
      55px
  );
  height: calc(
    ${(props) => (isNaN(+props.height) ? props.height : props.height + "px")} -
      110px
  );

  & > .${Classes.PANEL_STACK2_VIEW} {
    border-right: none !important;
  }
`;

const PanelStackContainer = styled.div`
  display: block;
`;

export interface CustomDialogProps extends DialogProps {
  isOpen: boolean;
  onClose: () => void;
  width?: number | string;
  height?: number | string;
  withCard?: boolean;
  /** WARNING: Passing a value to this property enables PanelStack mode */
  initialPanelForStack?: Panel<any>;
}

export const CustomDialog: React.FunctionComponent<CustomDialogProps> = ({
  width = 500,
  height = "auto",
  children,
  isCloseButtonShown = true,
  icon,
  withCard,
  initialPanelForStack,
  onClose,
  ...dialogProps
}) => {
  const { t } = useCommonHooks();
  const [panelStack, setPanelStack] = React.useState<Panel<any>[]>([]);

  // Initialization
  const previousInitialPanel = usePrevious(initialPanelForStack);
  React.useEffect(() => {
    if (initialPanelForStack && !previousInitialPanel) {
      setPanelStack([initialPanelForStack]);
    }
  }, [initialPanelForStack, previousInitialPanel]);

  // PanelStack usage
  const addToPanelStack = React.useCallback(
    (newPanel: Panel<any>) => setPanelStack((stack) => [...stack, newPanel]),
    []
  );

  const removeFromPanelStack = React.useCallback(() => {
    setPanelStack((stack) => stack.slice(0, -1));
  }, []);

  const pnPopupClose = React.useCallback(() => {
    if (initialPanelForStack) {
      setPanelStack([initialPanelForStack]);
    }
    onClose();
  }, [initialPanelForStack, onClose]);

  const finalChildren = React.useMemo(
    () =>
      !!initialPanelForStack && panelStack?.length > 0 ? (
        <PanelStackContainer>
          <StyledPanelStack
            width={width.toString()}
            height={height.toString()}
            onOpen={addToPanelStack}
            onClose={removeFromPanelStack}
            stack={panelStack}
            renderActivePanelOnly={false}
            showPanelHeader={false}
          />
          {panelStack.length > 1 && (
            <InlineButtonContainer>
              <Button
                intent={Intent.PRIMARY}
                icon={IconNames.ARROW_LEFT}
                text={t(ETLCodes.GoBack)}
                onClick={removeFromPanelStack}
              />
            </InlineButtonContainer>
          )}
        </PanelStackContainer>
      ) : (
        children
      ),
    [
      addToPanelStack,
      children,
      height,
      initialPanelForStack,
      panelStack,
      removeFromPanelStack,
      t,
      width,
    ]
  );

  return (
    <StyledDialog
      style={{ width: width, height: height }}
      icon={icon || "edit"}
      isCloseButtonShown={isCloseButtonShown}
      onClose={pnPopupClose}
      {...dialogProps}
      usePortal={true}
    >
      <DialogBody>
        {withCard ? (
          <CardFieldGroup>{finalChildren}</CardFieldGroup>
        ) : (
          finalChildren
        )}
      </DialogBody>
    </StyledDialog>
  );
};
