import React from "react";
import { useQuery } from "react-query";

import { SystemApi, SystemInfoDto } from "../api";
import { useApiService } from "../hooks";
import { useLocalStorageState } from "ahooks";
import i18n from "i18next";
import { useAuth } from "./AuthContext";

const GlobalDataContext = React.createContext<IGlobalDataContext>(null!);

interface GlobalDataProviderProps {
  children: React.ReactNode;
}

export const GlobalDataProvider: React.FunctionComponent<
  GlobalDataProviderProps
> = ({ children }) => {
  const systemApi = useApiService(SystemApi);

  const { user } = useAuth();

  const [currentLanguage, setCurrentLanguage] = useLocalStorageState(
    "language",
    {
      defaultValue: user?.languageCode?.toLowerCase() ?? "fr",
    }
  );

  React.useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    }
  }, [currentLanguage]);

  const changeCurrentLanguage = React.useCallback(
    (langCode: string) => {
      setCurrentLanguage(langCode);
    },
    [setCurrentLanguage]
  );

  const fetchGlobalData = React.useCallback(async () => {
    const systemInfo = await systemApi.get();
    return { systemInfo };
  }, [systemApi]);

  const {
    data: globalData,
    isFetching: globalDataLoading,
    error,
    refetch,
  } = useQuery("globaldata", fetchGlobalData, {
    staleTime: Infinity,
  });

  const appVersion = React.useMemo(() => {
    if (globalData?.systemInfo?.applicationVersion) {
      console.log(
        `CCB Portal Granulat - Backend Version ${globalData?.systemInfo?.applicationVersion}`
      );
      return globalData?.systemInfo?.applicationVersion.split(".Branch")[0];
    } else {
      return "";
    }
  }, [globalData]);

  const backendNotReachable = React.useMemo(
    () => globalDataLoading || !!error,
    [error, globalDataLoading]
  );

  const contextValue = React.useMemo(() => {
    return {
      systemInfo: globalData?.systemInfo,
      loading: globalDataLoading,
      backendNotReachable,
      appVersion,
      retry: refetch,
      shortVersion: appVersion?.split("+")[0],
      currentLanguage,
      changeCurrentLanguage,
    };
  }, [
    appVersion,
    backendNotReachable,
    changeCurrentLanguage,
    currentLanguage,
    globalData?.systemInfo,
    globalDataLoading,
    refetch,
  ]);

  return (
    <GlobalDataContext.Provider value={contextValue}>
      {children}
    </GlobalDataContext.Provider>
  );
};

interface IGlobalDataContext {
  systemInfo?: SystemInfoDto;
  appVersion: string;
  shortVersion: string;
  backendNotReachable: boolean;
  loading: boolean;
  retry: () => void;
  currentLanguage: string;
  changeCurrentLanguage: (langCode: string) => void;
}

export const useGlobalData = () => React.useContext(GlobalDataContext);
