import {
  Button,
  ButtonProps,
  Classes,
  Colors,
  HotkeyConfig,
  MaybeElement,
  useHotkeys,
} from "@blueprintjs/core";
import { FormikProps } from "formik";
import {
  FGCustomPanel,
  FlexFieldGroup,
  FormGenerator,
  IFGContext,
  IFormGeneratorProps,
  InlineButtonContainer,
} from "nsitools-react";
import React from "react";
import styled from "styled-components";

import { CancelButton, DeleteButton, SaveButton } from ".";
import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";
import { FGRouteDirtyChecker } from "./formGenerator/FGRouteDirtyChecker";

export interface CustomFormGeneratorProps<T>
  extends Omit<IFormGeneratorProps<T>, "children"> {
  loading?: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
  deleting?: boolean;
  saving?: boolean;
  editMode?: boolean;
  hideButtons?: boolean;
  editable?: boolean;
  cancelEdit?: boolean;
  showDeleteButton?: boolean;
  bottomLeftButtonProps?: ((ctx: IFGContext) => ButtonProps[]) | ButtonProps[];
  bottomRightButtonProps?: ((ctx: IFGContext) => ButtonProps[]) | ButtonProps[];
  bottomLeftButtons?: (ctx: IFGContext) => MaybeElement;
  bottomRightButtons?: (ctx: IFGContext) => MaybeElement;
  dialogMode?: boolean;
  enableHotkeys?: boolean;
  maxWidth?: string | number;
  additionalButtons?: React.ReactNode;
  saveButtonClassName?: string;
  ignoreDirtyRouteCheck?: boolean;
}

const StyledForm = styled.div`
  & .${Classes.LABEL} {
    color: ${Colors.DARK_GRAY5};
    font-size: small;
  }

  & {
    input,
    textarea,
    .textContainer {
      font-weight: 500;
    }
  }

  & .${Classes.FORM_GROUP} {
    margin: 0 0 5px !important;
  }

  & .${Classes.HTML_TABLE} {
    border: 0 none !important;
  }

  flex: 1;
  padding-bottom: 0.5rem;
`;

const CustomButtonContainer = styled(InlineButtonContainer)`
  .left,
  .right > * + * {
    margin-left: 0.25rem;
  }
`;

export function CustomFormGenerator<T>(
  props: React.PropsWithChildren<CustomFormGeneratorProps<T>>
) {
  const {
    children,
    loading = false,
    deleting,
    onDelete,
    onCancel,
    saving,
    minLabelWidth = 180,
    labelAlignment = "right",
    fill = true,
    showColons = false,
    inline = true,
    boldLabel = false,
    hideButtons = false,
    showDeleteButton = true,
    bottomLeftButtonProps,
    bottomRightButtonProps,
    bottomLeftButtons,
    bottomRightButtons,
    editMode,
    enableHotkeys = false,
    maxWidth,
    additionalButtons,
    saveButtonClassName,
    ignoreDirtyRouteCheck = false,
    ...otherProps
  } = props;
  const { t, tUnsafe } = useCommonHooks();
  const formikInnerRef = React.useRef<FormikProps<T>>();

  const isSaveAllowed = React.useCallback(
    (formik: FormikProps<T>) =>
      formik.dirty || formik.touched || formik.submitCount > 0,
    []
  );

  const hotkeys = React.useMemo<HotkeyConfig[]>(
    () =>
      enableHotkeys
        ? [
            {
              combo: "ctrl+s",
              global: true,
              label: t(ETLCodes.Save),
              preventDefault: true,
              stopPropagation: true,
              allowInInput: true,
              onKeyDown: () => {
                const formik =
                  (otherProps.formikInnerRef as any).current ??
                  formikInnerRef.current;
                return isSaveAllowed(formik) ? formik?.submitForm() : null;
              },
            },
          ]
        : [],
    [enableHotkeys, isSaveAllowed, otherProps.formikInnerRef, t]
  );

  useHotkeys(hotkeys);

  const getCustomLeftButtonProps = React.useCallback(
    (ctx: IFGContext<any>) => {
      let buttonProps: ButtonProps[];
      if (typeof bottomLeftButtonProps === "function") {
        buttonProps = bottomLeftButtonProps(ctx);
      } else {
        buttonProps = bottomLeftButtonProps;
      }
      return buttonProps?.map((btp) => <Button {...btp} />) || [];
    },
    [bottomLeftButtonProps]
  );

  const getCustomRightButtonProps = React.useCallback(
    (ctx: IFGContext<any>) => {
      let buttonProps: ButtonProps[];
      if (typeof bottomRightButtonProps === "function") {
        buttonProps = bottomRightButtonProps(ctx);
      } else {
        buttonProps = bottomRightButtonProps;
      }
      return buttonProps?.map((btp) => <Button {...btp} />) || [];
    },
    [bottomRightButtonProps]
  );

  return (
    <StyledForm
      style={{
        maxWidth: maxWidth,
      }}
    >
      <FormGenerator
        minLabelWidth={minLabelWidth}
        labelAlignment={inline ? labelAlignment : "left"}
        fill={fill}
        inline={inline}
        boldLabel={boldLabel}
        showColons={showColons}
        loading={loading}
        translateFn={tUnsafe}
        formikInnerRef={formikInnerRef}
        responsiveColumnMinWidth={600}
        editMode={editMode}
        {...otherProps}
        touchOnSubmit
        preventDefaultSaveOnEnter
      >
        <FGRouteDirtyChecker ignoreDirtyRouteCheck={ignoreDirtyRouteCheck} />
        <FlexFieldGroup gap="0.5rem">
          {children}
          {!hideButtons && (
            <FGCustomPanel>
              {(ctx) => (
                <CustomButtonContainer>
                  <div className="left">
                    {onDelete && editMode && showDeleteButton && (
                      <DeleteButton
                        loading={deleting}
                        minimal={false}
                        onDelete={onDelete}
                      />
                    )}
                    {bottomLeftButtons?.(ctx)}
                    {getCustomLeftButtonProps(ctx)}
                  </div>
                  <div className="right">
                    {bottomRightButtons?.(ctx)}
                    {getCustomRightButtonProps(ctx)}
                    {onCancel && (
                      <CancelButton minimal={false} onClick={onCancel} />
                    )}
                    {additionalButtons}
                    {editMode && (
                      <SaveButton
                        className={saveButtonClassName}
                        minimal={false}
                        loading={saving}
                        disabled={!isSaveAllowed(ctx?.formik)}
                      />
                    )}
                  </div>
                </CustomButtonContainer>
              )}
            </FGCustomPanel>
          )}
        </FlexFieldGroup>
      </FormGenerator>
    </StyledForm>
  );
}
