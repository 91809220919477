import { useQuery } from "react-query";

import { useApiService } from "../useApiService";
import {
  DataTranslationDto,
  DataTranslationValueDto,
  LanguageApi,
} from "../../api";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth, useEventsContext } from "../../contexts";
import { useLocalStorageState, useMount } from "ahooks";

export function useDisplayLanguages() {
  const languageApi = useApiService(LanguageApi);
  const { dispatchEvent } = useEventsContext();
  const { i18n } = useTranslation();

  const { user } = useAuth();

  const [languageFromStorage, setLanguageFromStorage] = useLocalStorageState(
    "language",
    {
      defaultValue: user?.languageCode?.toLowerCase() ?? "fr",
    }
  );

  const { data, isFetching, refetch } = useQuery(
    "ref-languages",
    async () => {
      return languageApi.getLanguages();
    },
    { cacheTime: Infinity, initialData: [] }
  );

  const buildDefaultDataTranslations = React.useCallback(() => {
    const translations = data.map(
      (l) =>
        new DataTranslationValueDto({
          languageId: l.id,
          languageCode: l.code,
          text: "",
        })
    );
    return new DataTranslationDto({
      dataTranslationValues: translations,
    });
  }, [data]);

  const currentLanguage = React.useMemo(() => {
    return data.find(
      (d) => d.code.toLowerCase() === i18n.language.toLowerCase()
    );
  }, [i18n.language, data]);

  const changeLanguage = React.useCallback(
    async (code: string) => {
      await i18n.changeLanguage(code.toLowerCase());
      dispatchEvent("LanguageChanged");
      setLanguageFromStorage(code.toLowerCase());
      window.location.reload();
    },
    [dispatchEvent, i18n, setLanguageFromStorage]
  );

  useMount(() => {
    if (languageFromStorage.toLowerCase() !== i18n.language.toLowerCase()) {
      changeLanguage(languageFromStorage);
    }
  });

  return {
    languages: data,
    loadingLanguages: isFetching,
    refetchLanguages: refetch,
    buildDefaultDataTranslations,
    currentLanguage,
    changeLanguage,
  };
}
