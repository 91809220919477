import React from "react";
import styled from "styled-components";
import { Icon, MaybeElement } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons/lib/esnext";

const Container = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface TabTitleProps {
  icon?: IconName | MaybeElement;
  title: string;
}

export const TabTitle: React.FC<TabTitleProps> = ({ icon, title }) => {
  return (
    <Container>
      {icon && <Icon icon={icon} />}
      <span>{title}</span>
    </Container>
  );
};
