import { ActionCode } from "./api";
import { ERoutes } from "./AppRouter";
import { INavigationItem } from "./components";
import { ETLCodes } from "./locales";

export const BaseAppNavigation: INavigationItem[] = [
  {
    name: ETLCodes.DeliveryOrders,
    alternativeIcon: "fa-solid fa-truck",
    route: ERoutes.deliveryOrders,
    actions: [ActionCode.ViewDeliveryOrders],
  },
  {
    name: ETLCodes.Customers,
    alternativeIcon: "fa-solid fa-user-tie",
    route: ERoutes.customers,
    actions: [ActionCode.ViewCustomer],
  },
  {
    name: ETLCodes.Administration,
    icon: "cog",
    actions: [],
    items: [
      {
        name: ETLCodes.Users,
        icon: "people",
        route: ERoutes.users,
        actions: [ActionCode.ViewUser],
      },
      {
        name: ETLCodes.ActionRoles,
        icon: "hat",
        route: ERoutes.actionRoles,
        actions: [ActionCode.EditActionGroup],
      },
      {
        name: ETLCodes.Translations,
        icon: "text-highlight",
        route: ERoutes.translations,
        actions: [ActionCode.EditTranslations],
      },
      {
        name: ETLCodes.DataTranslations,
        icon: "data-connection",
        route: ERoutes.dataTranslations,
        actions: [ActionCode.EditDataTranslations],
      },
    ],
  },
];

// Fonction pour remplir le tableau actions de l'objet Administration
const populateAdministrationActions = (navigation) => {
  for (const item of navigation) {
    if (item.name === ETLCodes.Administration) {
      const allActions = [];
      item.items.forEach((subItem) => {
        if (subItem.actions) {
          allActions.push(...subItem.actions);
        }
        if (subItem.items) {
          subItem.items.forEach((nestedSubItem) => {
            if (nestedSubItem.actions) {
              allActions.push(...nestedSubItem.actions);
            }
          });
        }
      });
      item.actions = allActions;
      break; // Sortir de la boucle une fois que nous avons trouvé et mis à jour l'élément Administration
    }
  }
};

// Appel de la fonction pour mettre à jour l'objet
populateAdministrationActions(BaseAppNavigation);

const InnerFindAppNavigationItemFromRoute = (
  pathname: string,
  navItem: INavigationItem
): INavigationItem | null => {
  if (navItem?.route && pathname.startsWith(navItem.route)) {
    return navItem;
  }
  const nextNavItems = navItem.items;
  if (nextNavItems && nextNavItems?.length > 0) {
    for (const i of nextNavItems) {
      const found = InnerFindAppNavigationItemFromRoute(pathname, i);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const FindAppNavigationItemFromRoute = (pathname: string) => {
  for (const i of BaseAppNavigation) {
    const found = InnerFindAppNavigationItemFromRoute(pathname, i);
    if (found) return found;
  }
  return null;
};
