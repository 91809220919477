import React from "react";
import styled from "styled-components";
import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";
import { format } from "date-fns";

export interface IModifedByDataObject {
  updatedBy?: string;
  updatedOn?: Date;
  createdOn?: Date;
}

export interface ModifiedByProps {
  data?: IModifedByDataObject;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  flex: 1;

  & > span {
    font-style: italic;
  }
`;

export const ModifiedBy: React.FunctionComponent<ModifiedByProps> = ({
  data,
}) => {
  const { t } = useCommonHooks();
  if (data?.updatedBy && data?.updatedOn) {
    return (
      <Container>
        <span>
          {t(ETLCodes.ModifiedOn)}&nbsp;
          {format(data?.updatedOn, "dd/MM/yyyy")}
          &nbsp;{t(ETLCodes.At)}&nbsp;{format(data?.updatedOn, "HH:mm")}
          &nbsp;{t(ETLCodes.By)}&nbsp;{data?.updatedBy}
        </span>
      </Container>
    );
  }
  return null;
};
