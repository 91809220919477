import { useApiService, useRouter, useTl } from "..";
import {
  useAuth,
  useBreadcrumbData,
  useDialog,
  useEventsContext,
  useGlobalData,
  useNavigationContext,
} from "../../contexts";
import { useValidators } from "./useValidators";
import {
  ActionRoleApi,
  CustomerApi,
  DataTranslationApi,
  DeliveryOrderApi,
  ReferentialApi,
  SearchCriteriaApi,
  SystemApi,
  TranslationApi,
  TranslationCodeApi,
  UserApi,
} from "../../api";
import { getEnvironmentTheme, getTheme, SiteEnvironment } from "../../theme";
import { useManageValidationErrors } from "../useManageValidationErrors";
import React from "react";

export function useCommonHooks() {
  const environment = useGlobalData().systemInfo?.environment;
  const actionRoles = useApiService(ActionRoleApi);
  const system = useApiService(SystemApi);
  const dataTranslations = useApiService(DataTranslationApi);
  const referentials = useApiService(ReferentialApi);
  const translationCodes = useApiService(TranslationCodeApi);
  const translations = useApiService(TranslationApi);
  const user = useApiService(UserApi);
  const searchCrterias = useApiService(SearchCriteriaApi);
  const deliveryOrders = useApiService(DeliveryOrderApi);
  const customers = useApiService(CustomerApi);

  const apis = React.useMemo(
    () => ({
      actionRoles,
      dataTranslations,
      referentials,
      translationCodes,
      translations,
      user,
      searchCrterias,
      system,
      deliveryOrders,
      customers,
    }),
    [
      actionRoles,
      dataTranslations,
      referentials,
      translationCodes,
      translations,
      user,
      searchCrterias,
      system,
      deliveryOrders,
      customers,
    ]
  );
  return {
    ...useTl(),
    router: useRouter(),
    ...useBreadcrumbData(),
    ...useAuth(),
    ...useGlobalData(),
    ...useEventsContext(),
    ...useNavigationContext(),
    ...useDialog(),
    ...useManageValidationErrors(),
    validators: useValidators(),
    theme: getTheme("base"),
    environmentTheme: getEnvironmentTheme(environment as SiteEnvironment),
    apis,
  };
}
