import { showError, showSuccess, ValidationErrorsType } from "nsitools-react";
import React from "react";
import { QueryKey, useMutation, useQuery, useQueryClient } from "react-query";

import { useCommonHooks } from ".";
import { ErrorDetails } from "../api";
import { ETLCodes } from "../locales";
import { useManageValidationErrors } from "./useManageValidationErrors";
import {
  enableSkipDirtyCheck,
  clearSkipDirtyCheck,
} from "../components/formGenerator/FGRouteDirtyChecker";

interface UseCrudApiOptions<T> {
  queryKey: QueryKey;
  fetchFn: () => Promise<T>;
  fetchEnabled?: boolean;
  saveFn?: (data: T) => Promise<number>;
  successMessage?: string;
  onSaved?: (data: T) => any;
  deleteFn?: (data: T) => Promise<void>;
  onDeleteRoute?: string | ((data: T) => string);
  onDeleted?: (data: T) => any;
  serverValidationRootKey?: string;
  editParamIdName?: string;
  initialData?: T;
  refetchAfterSave?: boolean;
  childArraysToSanitize?: string[];
  retry?: boolean;
  shouldBeRedirected?: boolean;
}

export function useCrudApi<T>({
  queryKey,
  fetchFn,
  saveFn,
  onSaved,
  onDeleteRoute,
  deleteFn,
  onDeleted,
  serverValidationRootKey,
  successMessage,
  editParamIdName = "id",
  initialData,
  fetchEnabled = true,
  refetchAfterSave,
  childArraysToSanitize = [],
  retry = true,
  shouldBeRedirected = false,
}: UseCrudApiOptions<T>) {
  const { t, tUnsafe, router, notifyGlobalError } = useCommonHooks();
  const { parseValidationErrors } = useManageValidationErrors();
  const queryClient = useQueryClient();
  const { data, isFetching, refetch } = useQuery(queryKey, fetchFn, {
    initialData,
    enabled: fetchEnabled,
    retry,
  });

  const innerSaveFn = React.useCallback(
    (data: T) => {
      if (childArraysToSanitize.length > 0) {
        childArraysToSanitize.forEach((k) =>
          data[k]?.filter((p) => p.id < 0)?.forEach((p) => (p.id = 0))
        );
      }
      return saveFn(data);
    },
    [childArraysToSanitize, saveFn]
  );

  const {
    mutate: onSave,
    error: saveError,
    isError: isSaveError,
    isLoading: isSaving,
  } = useMutation(innerSaveFn, {
    onSuccess: (id, variables) => {
      enableSkipDirtyCheck();
      const saved = { ...variables, id } as T;
      queryClient.setQueriesData(queryKey, saved);
      if (onSaved) onSaved(saved);
      else {
        if (shouldBeRedirected) router.replaceEditRouteId(id, editParamIdName);
        if (successMessage) showSuccess(successMessage);
      }
      if (refetchAfterSave) refetch();
      clearSkipDirtyCheck();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (error: any, variables) => {
      const details: ErrorDetails[] = JSON.parse(error.response);
      details?.map((err) => {
        if (err?.errorCode) {
          showError(tUnsafe(`BackendError_${err.errorCode}`));
        } else {
          showError(t(ETLCodes.SaveError));
        }
      });
    },
  });

  const {
    mutate: onDelete,
    isError: isDeleteError,
    error: deleteError,
    isLoading: isDeleting,
  } = useMutation(deleteFn, {
    onSuccess: (_, variables) => {
      if (!onDeleteRoute) {
        if (!onDeleted) {
          const message =
            "onDeletedRoute property must be defined to redirect user after successfull deletion";
          console.error(message);
          showError(message);
        } else {
          onDeleted(variables);
        }
      } else {
        let route = "";
        if (typeof onDeleteRoute === "function") {
          route = onDeleteRoute(variables);
        } else {
          route = onDeleteRoute;
        }
        router.replace(route);
      }
    },
  });

  const [validationErrors, setValidationErrors] =
    React.useState<ValidationErrorsType>({});

  React.useEffect(() => {
    if (isSaveError && saveError) {
      const err = parseValidationErrors(saveError, serverValidationRootKey);
      setValidationErrors(err);
    } else {
      setValidationErrors({});
    }
  }, [isSaveError, parseValidationErrors, saveError, serverValidationRootKey]);

  React.useEffect(() => {
    if (isDeleteError && deleteError) {
      const err = parseValidationErrors(deleteError, serverValidationRootKey);
      setValidationErrors(err);
      notifyGlobalError(deleteError, serverValidationRootKey, true);
    } else {
      setValidationErrors({});
    }
  }, [
    deleteError,
    isDeleteError,
    notifyGlobalError,
    parseValidationErrors,
    serverValidationRootKey,
  ]);

  const setValidationErrorsFromExternal = React.useCallback(
    (errors: ValidationErrorsType) => {
      setValidationErrors((prev) => ({ ...prev, ...errors }));
    },
    []
  );

  const onDeleteFn = React.useCallback(() => {
    onDelete(data);
  }, [data, onDelete]);

  React.useEffect(() => {
    if (validationErrors["id"]) {
      validationErrors["id"].forEach((msg) => showError(tUnsafe(msg)));
    }
  }, [tUnsafe, validationErrors]);

  return {
    onSave,
    isSaving,
    validationErrors,
    refetch,
    data,
    isFetching,
    onDelete: onDeleteFn,
    deleteError,
    isDeleting,
    setValidationErrors: setValidationErrorsFromExternal,
  };
}
