export enum ETLCodes {
  Administration,
  Users,
  ActionRoles,
  Translations,
  DataTranslations,
  NotAuthorized,
  UserNotAuthorizedMessage,
  Language,
  Roles,
  Action,
  UserActions,
  LinkCopiedSuccessfully,
  AccessCollaborationSpace,
  GoBack,
  Save,
  Home,
  SaveError,
  EntityName,
  EntityId,
  FieldName,
  TranslationsImportSuccess,
  ImportTranslationFile,
  Logout,
  No,
  Yes,
  Confirm,
  Image,
  ImageIsNotInTheCorrectFormatErrorMessage,
  ClickOnPictureToEdit,
  GetTemplate,
  ModifiedOn,
  At,
  By,
  Add,
  Cancel,
  Clear,
  Copy,
  ConfirmDelete,
  Delete,
  Edit,
  Download,
  Export,
  Import,
  Menu,
  Refresh,
  SearchCriteriaPanel_Search,
  Select,
  ConfirmDisable,
  ConfirmEnable,
  Disable,
  Enable,
  User,
  Validate,
  View,
  GeneralNoData,
  PleaseSelectCriteria,
  FormDirtyCancelTitle,
  FormDirtyUnsavedMessage,
  FormDirtyCancelMessage,
  FormDirtyCancelButton,
  FormDirtyLeaveButton,
  GeneralLoading,
  UnFixMenuTooltip,
  FixMenuTooltip,
  QuickAccess,
  Error,
  ImportSuccess,
  TableCriterias,
  TableResults,
  Required,
  ErrorMessage_Unauthorized_title,
  ErrorMessage_Unauthorized,
  TranslationCode,
  UserSaved,
  FirstName,
  LastName,
  Login,
  IsActive,
  NewUser,
  Details,
  Role,
  UserList,
  DeliveryOrders,
  CollectionSlip,
  LoadingRequest,
  Quantity,
  Name,
  CustomerList,
  Customers,
  NewCustomer,
  CustomerSaved,
  Customer,
  Destination,
  ProdutionSite,
  ProductCode,
  Date,
  CarrierLongName,
  TractorLicensePlate,
  TrailerLicensePlate,
  CustomerRef,
  CustomerName,
  CustomerCode,
  StartDate,
  EndDate,
  Dossier,
  CustomerType,
  IsCanceled,
  UnSelectAll,
  CarrierShortName,
  OriginalOrderCorrection,
  EndTime,
  SelectAll,
  PDFPreviewer,
  NoPDFToDisplay,
  CannotDisplayPDF,
  EnsureMfa,
  Search,
  ToolTip_PreviewPdf,
  ToolTip_DownloadPdf,
}
