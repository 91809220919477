import { NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { PageBase } from "../../components";

interface IUnauthorizedPageProps {}

export const UnauthorizedPage: React.FunctionComponent<
  IUnauthorizedPageProps
> = () => {
  const { t } = useTl();

  return (
    <PageBase>
      <NonIdealState
        title={t(ETLCodes.ErrorMessage_Unauthorized_title)}
        icon={IconNames.CROSS}
        description={t(ETLCodes.ErrorMessage_Unauthorized)}
      />
    </PageBase>
  );
};
