import "./variables.default.css";
// import "./index.css";
import "./index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { baseUrl } from "./apiConfig";
import { App } from "./App";
import { AppProvider } from "./AppProvider";
import { initTranslator } from "./locales";

// If pdf viewer is enabled
import { pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
initTranslator(baseUrl);

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppProvider>
    <App />
  </AppProvider>
);
